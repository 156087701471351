import LanguageService from "../../../services/languageService";
import styled from '../home.module.css';

function VideoItem(props) {

    return (<tbody><tr key={props.ID}>
        <td>
            <div className={"iframe-container"}>
                <iframe title={props.ID} src={props.url}/>
            </div>
        </td>
    </tr>
    <tr key={`${props.ID}_details`}>
        <td>
            <table width={"100%"}>
                <tbody>
                <tr>
                    <td className={styled.filmpjestitle}>{LanguageService.translate('film')}</td>
                    <td>
                        <a href={props.origin.origin_url} target={"_blank"} rel={"noreferrer"}>
                            {props.origin.origin}
                        </a>
                    </td>
                </tr>
                <tr>
                    <td className={styled.filmpjestitle}>{LanguageService.translate('usedmusic')}</td>
                    <td>{props.music.naam}</td>
                </tr>

                <tr>
                    <td className={styled.filmpjestitle}>{LanguageService.translate('composernaam')}</td>
                    <td>{props.music.composer}</td>
                </tr>
                <tr>
                    <td className={styled.filmpjestitle}>{LanguageService.translate('album')}</td>
                    <td>{props.music.album}</td>
                </tr>
                <tr>
                    <td className={styled.filmpjestitle}>{LanguageService.translate('musicsource')}</td>
                    <td>
                        <a href={props.music.origin_url} target={"_blank"} rel={"noreferrer"}>
                            {props.music.origin}
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </td>
    </tr>
        </tbody>
    )
}

export default VideoItem;
