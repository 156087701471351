import DatabaseService from "../../../services/databaseService";
import MovieRow from "./movieRow";
import LanguageService from "../../../services/languageService";
import {useCallback} from "react";
import styled from './compare.module.css';
import SurveyResponseService from "../../../services/surveyResponseService";

function Compare() {

    const {response, serie} = SurveyResponseService.getSurveyResponse();

    const movieSeries = DatabaseService.sortFilmsBySurveySeries(DatabaseService.getSeries(), serie);

    const seriesKeyComparator = useCallback((a, b) => {
        const s = serie ? serie[serie.length - 1] : 1;

        const clipA = movieSeries[a].find(x => x.Vraagserie === s);
        const clipB = movieSeries[b].find(x => x.Vraagserie === s);

        return clipA.ID - clipB.ID;
    }, [serie, movieSeries]);

    return <div>
        <h1>{LanguageService.translate('methodHeader')}</h1>
        {LanguageService.translate('method')}
        {!response && LanguageService.translate("methodHintDoSurvey")}
        {response && <div>
            {LanguageService.translate("methodSurveyFinished")}
            <div className={styled.legend}>
            <h3>{LanguageService.translate('legend')}</h3>
            <div>
                <div className={"outer"}><span className={`inner ${styled.inner}`}/><span>{LanguageService.translate('community_average')}</span> </div>
            </div>
            <div>
                <div className={"outer"}><span className={`inner ${styled.inner} user`}/><span>{LanguageService.translate('your_average')}</span> </div>
            </div>
        </div></div>}
        {Object.keys(movieSeries).sort(seriesKeyComparator).map(vraagserie =>
            <MovieRow
                key={vraagserie}
                response={response}
                currentSerie={vraagserie}
                serie={serie}
                series={movieSeries[vraagserie]}
            />
    )}</div>
}

export default Compare;
