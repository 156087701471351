import LanguageService from "../../../services/languageService";
import styles from './compare.module.css';
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

function MovieDetailsRow({series, element, activeSerieID, label, value, href}) {

    const location = useLocation();
    const [_label, _setLabel] = useState();

    useEffect(() => {
        _setLabel(LanguageService.translate(label)?.replace(/\*$/g, ''));
    }, [label, location])

    return <tr>
        <td className={styles.headerLabel}>{_label}</td>
        {series.map(clip =>
            <td key={clip.ID} className={clip.Vraagserie === activeSerieID ? styles.active : ''}>
                {element && element(clip)}
                {value && <div className={`${styles.row} ${styles.movieDetail}`}>
                    {label && <div className={[styles.halfColumn, styles.hideLarge, styles.headerLabel].join(" ")}>{_label}:</div>}
                    <div className={label ? styles.halfColumn : ''}>
                        {href && <a href={href(clip)} target={"_blank"} rel={"noreferrer"}>{value(clip)}</a>}
                        {!href && value(clip)}
                    </div>
                </div>
                }
            </td>
        )}
    </tr>

}

export default MovieDetailsRow;
