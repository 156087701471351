import language_strings from "../data/Languages";
import nationalities from "../data/nationalities";
import {Link} from "react-router-dom";

const LanguageService = {
    lang: "NL",
    translate(key) {
        if (language_strings[key]) {
            return language_strings[key][this.lang];
        } else {
            return key;
        }
    },
    done: {
        welcome: {
            NL: <div>
                <h1>Welkom</h1>
                <div className={"added-comment comment-left"}>
                    <div className={"comment infobubble"}>
                        Deze webpagina is bedoeld om een impressie te geven van de oorspronkelijke weergave.
                        Omdat wij geen data meer (willen) verzamelen zijn bepaalde functionaliteiten licht veranderd.
                        Deze verandering en andere contextuele hints worden aangegeven met dit soort infobubbels.
                        Alles buiten deze informatiebubbels is onderdeel van de oorspronkelijke weergave.
                    </div>
                    <p className={"commentTarget"}>Welkom op onze vernieuwde website. </p>
                </div>
                <p>
                    Via deze pagina hebben wij een onderzoek gedaan naar de invloed van filmmuziek op de sfeer die de kijker beleeft tijdens
                    het kijken van een filmfragment door bezoekers van deze pagina een serie filmpjes te laten zien en
                    vervolgens daar een aantal vragen over te stellen. De filmpjes waren verschillende versies van een
                    zelfde beeldfragment waarbij slechts de muziek was veranderd. Wij hebben de verschillen in de resultaten
                    tussen deze filmpjes gemeten en hier onze conclusies uit getrokken. Hoe ons onderzoek exact in elkaar zat en
                    welke conclusies wij getrokken hebben kunt u lezen in het op deze site beschikbaar gestelde rapport.
                </p>
                <p>
                    Het onderzoek is inmiddels afgerond. Wij hebben de resultaten verwerkt en het geheel aangeboden aan onze
                    opdrachtgever, het Guido de Brès. Als u mee heeft gedaan aan ons onderzoek door de enquête in te vullen willen
                    wij u daarvoor nogmaals hartelijk bedanken.
                </p>
                <p>Ook u kunt het rapport bezichtigen.
                    In het rapport leggen wij gedetailleerd uit hoe wij ons onderzoek hebben uitgevoerd.
                    Ook kunt u in het rapport een overzicht vinden van de verschillende aspecten die
                    naast muziek een invloed hebben op de sfeer en een overzicht van hoe verschillende
                    filmcomponisten te werk gaan als zij filmmuziek moeten maken.<br/>
                    Klik hiervoor op de link <Link to={"/NL/download"}>Download</Link> bovenaan de pagina. Naast het rapport (in pdf) kunt u hier ook de
                    broncode van de enquête downloaden dat u kunt gebruiken voor uw eigen onderzoek.
                </p>
                <div className={"added-comment comment-left"}>
                    <div className={"comment infobubble"}>
                        Een vernieuwde versie van deze pagina, inclusief overzicht van onderzoeksresultaten, is&nbsp;
                        <Link target={"_blank"} rel={"noreferrer"} to={"/NL/compare"}>hier</Link> te vinden.
                    </div>
                    <p className={"commentTarget"}>Op de pagina <Link to={"NL/videos"}>Filmfragmenten</Link> kunt u alle door ons bewerkte en in de enquête gebruikte filmfragmenten
                        nog eens terugkijken en deze rustig met elkaar vergelijken. Als u de enquête heeft gedaan heeft
                        u hoogst waarschijnlijk slechts een gedeelte van de filmpjes gezien. Het zou dan leuk kunnen zijn
                        om de andere of originele versies van de filmpjes die u heeft gezien ook eens te bekijken.
                    </p>
                </div>
                <div className={"added-comment comment-right"}>
                    <div className={"comment infobubble"}>
                        U kunt er ook voor kiezen eerst de enquête te doen, waarna u uw antwoorden kunt vergelijken met het gemeten gemiddelde.
                    </div>
                    <p className={"commentTarget"}>
                        Als u klikt op <Link to={"/survey"} target={"_blank"} rel={"noreferrer"}>Enquête</Link>&nbsp;
                        kunt u de originele enquête nog eens doen. Denk er hierbij aan dat de resultaten niet meer&nbsp;
                        verwerkt zullen worden en dit dus puur voor uzelf is.
                    </p>
                </div>
                <div className={"added-comment comment-left"}>
                    <div className={"comment infobubble"}>
                        Het gastenboek is niet langer actief, omdat wij geen (persoonlijke) data meer verzamelen.
                    </div>
                    <p className={"commentTarget"}>
                        Als laatste kunt u op de pagina <Link to={"/NL/guestbook"}>Gastenboek</Link> een berichtje achterlaten met uw
                        mening over het door ons uitgevoerde onderzoek of overige opmerkingen.
                    </p>
                </div>
                <p>Kijkt u gerust even rond en nogmaals bedankt voor uw medewerking! </p>
            </div>,
            UK: <div>
                <h1>Welcome</h1>
                <div className={"added-comment comment-left"}>
                    <div className={"comment infobubble"}>
                        This webpage is meant to give an impression of the original presentation.
                        We have changed or removed some of the original functionality because
                        we are no longer (willing to) collect data. These changes as well as other contextual hints
                        will be indicated with this style of information bubbles. Anything outside these bubbles
                        is part of the original presentation.
                    </div>
                    <p className={"commentTarget"}>Welcome to our renewed website.</p>
                </div>
                <p>
                    We have used this page to do research on the influence of film music on the ambiance experienced by the
                    viewer while he is watching a film fragment. We showed the visitors of this site a series of short
                    clips which we then asked them a few questions about. The clips were different versions of multiple films.
                    In these different versions only the music was altered. The difference between the results of these
                    different versions has been the basis for our conclusions. If you wish to know the details of our research
                    project and the conclusions we have drawn from the results you can download the report on this site.
                </p>
                <p>
                    By now we have finished our research. Once more we would like to thank everybody who has
                    participated by filling in the survey.
                </p>
                <p>
                    If you want to see the report you can click on the link <Link to={"/UK/download"}>Download</Link>&nbsp;
                    on the top of this page. Note that the report is only available in Dutch.
                    If you wish to have a preview of the contents of this report, please switch to Dutch. <br/>
                    On this page you can also download the source codes we used for the online survey.
                    If you wish to see or use those you can also click on <Link to={"/NL/download"}>Download</Link>.
                </p>
                <div className={"added-comment comment-left"}>
                    <div className={"comment infobubble"}>
                        A new and improved version of this page, including an overview of research results, can be found&nbsp;
                        <Link target={"_blank"} rel={"noreferrer"} to={"/UK/compare"}>here</Link>.
                    </div>
                    <p className={"commentTarget"}>
                        On the page <Link to={"UK/videos"}>Film fragments</Link> you can review every version of the
                        film fragments we used in the inquiry and compare them to each other.
                        If you have done the survey you most likely have not seen all the film fragments we used,
                        so it might be worth it to visit the page and see what we haven’t shown you yet.
                    </p>
                </div>
                <div className={"added-comment comment-right"}>
                    <div className={"comment infobubble"}>
                        You can also opt to first do the survey. In that case, you will be able to compare your response with
                        the measured average.
                    </div>
                    <p className={"commentTarget"}>
                        If you want to see what our survey looked like, click on the link&nbsp;
                        <Link to={"/survey"} target={"_blank"} rel={"noreferrer"}>Survey</Link>.
                        Note that we will no longer process the data so redoing the survey would be
                        purely for your own entertainment.
                    </p>
                </div>
                <div className={"added-comment comment-left"}>
                    <div className={"comment infobubble"}>
                        We have disabled the guestbook, as we are no longer collecting (personal) data.
                    </div>
                    <p className={"commentTarget"}>
                        On the last page, <Link to={"/UK/guestbook"}>Guestbook</Link>,
                        you can leave a comment about what you thought about the survey or
                        tell us anything else you want us to know.
                    </p>
                </div>

                <p>Take your time to look around and once more, thank you for your participation. </p>
            </div>
        },
        download: {
            NL: (detailsVisible, setDetailsVisible) => <div><h1>Download</h1><p>Hier kunt u ons onderzoeksrapport downloaden. Alles wat in dit rapport staat
                mag worden gebruikt voor eigen onderzoek of plezier mits de namen <i>Dick van Baardewijk</i> en <i>Jan
                    de Mooij</i> worden vermeld bij het gebruik van dit document of delen daarvan of bij het gebruik van
                onderzoeksideeën die in dit document beschreven staan. Door dit document te downloaden gaat u akkoord
                met deze voorwaarden.</p>
                <p><Link to={require('../assets/Filmmuziek_web.pdf')} target={"_blank"} title={"Het rapport"}>Download het rapport <i>Invloed
                    van filmmuziek op sfeer – Onderzoek naar de invloed van filmmuziek op de sfeer die de kijker ervaart
                    tijens het kijken van een film.</i></Link></p>
                <p/>
                <div className={"added-comment comment-left"}>
                    <div className={"comment infobubble"}>
                        <p>De broncode is niet langer beschikbaar. Om een zo compleet mogelijke representatie te geven van
                        de
                        oorspronkelijke staat van deze website blijft deze tekst beschikbaar, maar is de download link
                            gecensureerd.</p>
                        <p>
                        <a href="#" onClick={() => setDetailsVisible(prevState => !prevState)}>Geef me onnodige
                            details...</a>
                        </p>
                        <div className={"collapsible " + (detailsVisible ? "expanded" : "collapsed")}>
                            <p>
                                De huidige webpagina is gereverse-engineered in React vanuit de oorspronkelijke broncode
                            van de enquête die, ondanks onze gebrek aan kennis destijds van deze programmeertaal,
                            was geprogrammeerd in PHP. <br/>
                                Hierbij hebben wij gemerkt hoe moeilijk het was onze code uit
                            die tijd te interpreteren, waardoor de code zelf geen toegevoegde waarde meer heeft.
                            </p>
                            <p>Doordat de oorspronkelijke database verloren is gegaan met de tijd, was het ook niet
                            meer mogelijk een lokale instantie van de code te draaien, waardoor visuele referentie
                            voor het reverse-engineeren ontbrak.</p>
                            <p>Dit is ook de reden waarom wij, ondanks
                            onze oorspronkelijke intenties, de broncode niet langer beschikbaar stellen.
                        </p>
                        </div>
                    </div>
                    <div className={"commentTarget"}>
                        <p>Als u gebruik wilt maken van de broncode of delen daarvan die wij hebben geschreven voor het
                            afnemen van de enquête is dat ook mogelijk. Opnieuw geldt dat u bij het gebruik van de
                            broncode of delen daarvoor voor eigen gebruik, voor commercieel gebruik of voor eigen
                            onderzoek de namen <i>Dick van Baardewijk</i> en <i>Jan de Mooij</i> zult vermelden onder
                            aan de webpagina en in eventuele documenten die vastzitten aan de webpagina zoals rapporten
                            met resultaten en disclaimers. Wij kunnen niet aansprakelijk worden gesteld voor
                            onoverzichtelijkheid of fouten in de broncode of die ontstaan door het gebruik daarvan. Door
                            de broncode te downloaden gaat u akkoord met deze voorwaarden. </p>
                        <p>[Download broncode enquête] </p>
                    </div>
                </div>
                <p>Wij houden ons het recht voor op elk moment de content, het rapport en de broncode van de enquête van
                deze website te verwijderen zonder de bezoekers daar vooraf over mee te delen.
                    Wij nemen geen verantwoordelijkheid voor fouten en
                    onregelmatigheden</p>
                </div>,
        UK: (detailsVisible, setDetailsVisible) => <div><h1>Download</h1>
            <p>On this page you can download the report on our research.
                Everything in this report can be used freely for your own research or pleasure provided that you will
                give credit to us using the names <i>Dick van Baardewijk</i> and <i>Jan de Mooij</i> when you use this
                report or parts thereof or ideas described in this document or parts thereof.
                By downloading this document you agree with these conditions.<br/>
                Note that the report is available in Dutch only.</p>
                <p>
                    <Link to={require('../assets/Filmmuziek_web.pdf')} target={"_blank"} title={"Het rapport"}>Download the report
                        <i>Invloed van filmmuziek op sfeer – Onderzoek naar de invloed van filmmuziek op de sfeer die de kijker ervaart tijens het kijken van een film.</i>
                    </Link>
                </p>
                <p/>
                <div className={"added-comment comment-left"}>
                    <div className={"comment infobubble"}>
                        <p>This source code is no longer available. As to provide a complete as possible representation
                        of the original state of this website, this text remains available, but the download link
                        has been censored.
                        </p>
                        <p>
                        <a href="#" onClick={() => setDetailsVisible(prevState => !prevState)}>
                            Unnecessary details please...
                        </a>
                        </p>
                        <div className={"collapsible " + (detailsVisible ? "expanded" : "collapsed")}>
                            <p>The current page is reverse engineered in React from the original source code in PHP,&nbsp;
                            in which the original survey was programmed, despite our lack of understanding of PHP
                            at the time.<br/>
                            In doing so, we have realized how hard it was to interpret the code, showing us the code
                                itself provides no additional benefits.</p>
                            <p>
                            Because the original database has also been lost to time, it was not possible to run even
                            a local instance of the code, meaning we lacked visual representation when we
                            tried to reverse engineer this website.</p>
                            <p>This is also the reason why we, contrary to our
                            original intentions, no longer make the source code available.
                            </p>
                        </div>
                    </div>
                    <div className={"commentTarget"}>
                        <p>If you want to use the source code of the survey we have used you can as well, provided that, again, you will give full credit to us, it’s makers, using the names <i>Dick van Baardewijk</i> and <i>Jan de Mooij</i>. You can use this source code or parts thereof for yourself, for professional purposes or for research freely if you credit us at least on the page you will use this source code for itself and in the reports based on the results obtained by means of the source code or parts thereof. We cannot be held responsible for inconvenience or errors in the source code, nor for things using this source code will bring about. By downloading the source code you agree to these terms. </p>
                        <p> [Download source code survey] </p>
                    </div>
                    <p>
                        We reserve the right to remove the contents, the report, and the source code of the survey from
                        this web page without prior notice. We do not accept liability for mistakes or irregularities.
                    </p>
                </div>
                </div>
        }
    },
    getNationalities: () => [
        {key: -3, value: '', label: LanguageService.translate("selectnationaliteit")},
        {key: -2, value: 'none', label: LanguageService.translate('none')},
        {key: -1, value: 'prive', label: LanguageService.translate('prive')},
        ...Object.entries(nationalities).map((nationality, index) => {
                return {
                    value: nationality[0],
                    label: nationality[1][LanguageService.lang].props.children,
                    key: index
                }
            })
    ],

}

export default LanguageService;
