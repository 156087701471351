import {Link} from "react-router-dom";

const language_strings = {
    leeftijd: {
        Taal_ID: 1,
        Naam: "leeftijd",
        NL: "Leeftijd*",
        UK: "Age*"
    },
    geslacht: {
        Taal_ID: 2,
        Naam: "geslacht",
        NL: "Geslacht*",
        UK: "Gender*"
    },
    title: {
        Taal_ID: 3,
        Naam: "title",
        NL: "School onderzoek over films",
        UK: "School research about films"
    },
    instructiefilm: {
        Taal_ID: 4,
        Naam: "instructiefilm",
        NL: <center><iframe src="https://player.vimeo.com/video/17243883" /></center>,
        UK: <center><iframe src="https://player.vimeo.com/video/17237026" /></center>
    },
    titel1: {
        Taal_ID: 5,
        Naam: "titel1",
        NL: "Wie wij zijn en wat wij onderzoeken",
        UK: "Who we are and what we are researching"
    },
    titel2: {
        Taal_ID: 6,
        Naam: "titel2",
        NL: "Algemene vragen",
        UK: "General questions"
    },
    basisuitleg: {
        Taal_ID: 7,
        Naam: "basisuitleg",
        NL: "Om onze resultaten zo goed mogelijk te kunnen verwerken hebben wij enkele basisgegevens van u nodig. De resultaten blijven geheel anoniem.",
        UK: "In order to process the results correctly, we will ask you some basic information. De given information will remain completely anonymous."
    },
    selectleeftijd: {
        Taal_ID: 8,
        Naam: "selectleeftijd",
        NL: "Selecteer uw leeftijd",
        UK: "Select your age"
    },
    man: {
        Taal_ID: 9,
        Naam: "man",
        NL: "Man",
        UK: "Male"
    },
    vrouw: {
        Taal_ID: 10,
        Naam: "vrouw",
        NL: "Vrouw",
        UK: "Female"
    },
    kijkxp: {
        Taal_ID: 11,
        Naam: "kijkxp",
        NL: "Hoe vaak kijkt u films?*",
        UK: "How often do you watch films?*"
    },
    xpunvalid: {
        Taal_ID: 12,
        Naam: "xpunvalid",
        NL: "Selecteer wat voor u geldt",
        UK: "Select what applies to you"
    },
    xp1: {
        Taal_ID: 13,
        Naam: "xp1",
        NL: "Nooit",
        UK: "Never"
    },
    xp2: {
        Taal_ID: 14,
        Naam: "xp2",
        NL: "Zelden",
        UK: "Rarely"
    },
    xp3: {
        Taal_ID: 15,
        Naam: "xp3",
        NL: "Soms",
        UK: "Occasionally"
    },
    xp4: {
        Taal_ID: 16,
        Naam: "xp4",
        NL: "Vaak",
        UK: "Often"
    },
    onder: {
        Taal_ID: 17,
        Naam: "onder",
        NL: "tot",
        UK: "below"
    },
    next: {
        Taal_ID: 18,
        Naam: "next",
        NL: "Volgende",
        UK: "Next"
    },
    titel3: {
        Taal_ID: 19,
        Naam: "titel3",
        NL: "Filmfragment en vragen",
        UK: "Film fragment and questions"
    },
    vragenuitleg: {
        Taal_ID: 20,
        Naam: "vragenuitleg",
        NL: <p>Bekijk eerst het filmfragment en beantwoord daarna de vragen.<br/>Denk niet te lang na over de vragen. Als u een vraag niet kunt beantwoorden kunt u <i>3: Een beetje/Neutraal</i> invullen.</p>,
        UK: <p>Answer the following questions after watching this filmfragment. Do not think too long about the questions.<br/>If you don't</p>
    },
    aanwezigheid1: {
        Taal_ID: 21,
        Naam: "aanwezigheid1",
        NL: "Geen",
        UK: "No"
    },
    aanwezigheid2: {
        Taal_ID: 22,
        Naam: "aanwezigheid2",
        NL: "Weinig",
        UK: "Some"
    },
    aanwezigheid3: {
        Taal_ID: 23,
        Naam: "aanwezigheid3",
        NL: "Neutraal/weet ik niet",
        UK: "Neutral/don't know"
    },
    aanwezigheid4: {
        Taal_ID: 24,
        Naam: "aanwezigheid4",
        NL: "Veel",
        UK: "Much"
    },
    aanwezigheid5: {
        Taal_ID: 25,
        Naam: "aanwezigheid5",
        NL: "Zeer veel",
        UK: "Very much"
    },
    Q1: {
        Taal_ID: 26,
        Naam: "Q1",
        NL: "Spanning*",
        UK: "Suspense*"
    },
    Q2: {
        Taal_ID: 27,
        Naam: "Q2",
        NL: "Blijdschap*",
        UK: "Joy*"
    },
    Q3: {
        Taal_ID: 28,
        Naam: "Q3",
        NL: "Verdriet*",
        UK: "Sorrow*"
    },
    Q4: {
        Taal_ID: 29,
        Naam: "Q4",
        NL: "Rust*",
        UK: "Calm*"
    },
    Q5: {
        Taal_ID: 30,
        Naam: "Q5",
        NL: "Onrust*",
        UK: "Agitation*"
    },
    Q6: {
        Taal_ID: 31,
        Naam: "Q6",
        NL: "Geluk*",
        UK: "Happiness*"
    },
    Q7: {
        Taal_ID: 32,
        Naam: "Q7",
        NL: "Angst*",
        UK: "Fear*"
    },
    Q8: {
        Taal_ID: 33,
        Naam: "Q8",
        NL: "Teleurstelling*",
        UK: "Disappointment*"
    },
    Q9: {
        Taal_ID: 34,
        Naam: "Q9",
        NL: "Opluchting*",
        UK: "Relief*"
    },
    titel4: {
        Taal_ID: 35,
        Naam: "titel4",
        NL: "Bij het kijken van dit filmpje voelde ik",
        UK: "While watching this scene I felt"
    },
    vragenuitleg2: {
        Taal_ID: 36,
        Naam: "vragenuitleg2",
        NL: <p>Voor alle onderstaande emoties kunt u aangeven hoeveel deze voor u bij het kijken van dit filmpje aanwezig was. U vult een 1 in als de emotie totaal niet aanwezig was en een 5 als deze heel sterk aanwezig was.</p>,
        UK: <p>For each of the listed emotions you can indicate how strong it was present for you while watching this film fragment. You can select 1 if you did not feel the emotion at all, and 5 if you felt it strongly.</p>
    },
    ja: {
        Taal_ID: 37,
        Naam: "ja",
        NL: "Ja",
        UK: "Yes"
    },
    nee: {
        Taal_ID: 38,
        Naam: "nee",
        NL: "Nee",
        UK: "No"
    },
    herkenning: {
        Taal_ID: 39,
        Naam: "herkenning",
        NL: "Herkende u dit filmfragment?*",
        UK: "Did you recognise this film fragment?*"
    },
    opmerkingen: {
        Taal_ID: 40,
        Naam: "opmerkingen",
        NL: "Heeft u verder nog op- of aanmerkingen?",
        UK: "Do you have any comments about this film fragment or the asked questions?"
    },
    gebeurtenis: {
        Taal_ID: 41,
        Naam: "gebeurtenis",
        NL: "Hoe verwacht u dat deze scene afloopt?",
        UK: "How do you expect this scene will end?*"
    },
    oningevuld: {
        Taal_ID: 42,
        Naam: "oningevuld",
        NL: "Een of meerdere vragen zijn nog niet ingevuld. Controleer de rood gemarkeerde vragen.",
        UK: "One or more questions are not answered. Please check the red marked questions."
    },
    dank: {
        Taal_ID: 43,
        Naam: "dank",
        NL: <span>Dank u wel voor het invullen van onze enqu&ecirc;te. <br/>Als u wilt kunt u met het hier onderstaande formulier nog commentaar of tips geven op en over de enqu&ecirc;te. Dit is <b>niet verplicht</b>. Als u wilt dat wij contact met u opnemen, is noodzakelijk dat u een geldig E-mailadres invult. U kunt ook een E-mail sturen naar <a href="mailto:ourfilmresearch@gmail.com" title="Stuur ons een email">OurFilmResearch@gmail.com</a>.<br/><b>U kunt het venster sluiten</b> wanneer u wilt.</span>,
        UK: <span>Thank you very much for participating in our research project. <br />If you want you can use the form below to sent us your comments or tips about this enquiry, but this is <b>not compulsory</b>. If you want us to contact you, you need to enter a valid emailadres. You can also sent us an email using <a href="mailto:ourfilmresearch@gmail.com" title="Mail us">OurFilmResearch@gmail.com</a>. <br/><b>You can now close this window</b> whenever you want now.</span>
    },
    mailnaam: {
        Taal_ID: 44,
        Naam: "mailnaam",
        NL: "Naam*",
        UK: "Name*"
    },
    mailemail: {
        Taal_ID: 45,
        Naam: "mailemail",
        NL: "E-mailadres",
        UK: "Email adress"
    },
    mailopmerking: {
        Taal_ID: 46,
        Naam: "mailopmerking",
        NL: "Uw bericht*",
        UK: "Your comment*"
    },
    versturen: {
        Taal_ID: 47,
        Naam: "versturen",
        NL: "Versturen",
        UK: "Sent"
    },
    maildank: {
        Taal_ID: 48,
        Naam: "maildank",
        NL: "Dank u wel voor uw bericht.",
        UK: "Thank you for your comment."
    },
    mailnationaliteit: {
        Taal_ID: 49,
        Naam: "mailnationaliteit",
        NL: "In welk land woont u?*",
        UK: "What country do you live in?*"
    },
    dank2: {
        Taal_ID: 50,
        Naam: "dank2",
        NL: "Dank u voor uw reactie.",
        UK: "Thank you for your feedback."
    },
    stopmetafsluiten: {
        Taal_ID: 51,
        Naam: "stopmetafsluiten",
        NL: "Als u deze pagina nu verlaat worden uw gegevens niet opgeslagen. Weet u zeker dat u deze pagina wilt verlaten?",
        UK: "If you leave this page now, your information won&#39;t be saved. Are you sure you want to quit now?"
    },
    selectnationaliteit: {
        Taal_ID: 52,
        Naam: "selectnationaliteit",
        NL: "Selecteer uw nationaliteit",
        UK: "Select your nationality"
    },
    none: {
        Taal_ID: 53,
        Naam: "none",
        NL: "Geen",
        UK: "None"
    },
    soortfilm: {
        Taal_ID: 54,
        Naam: "soortfilm",
        NL: <span>In wat voor een soort film zou deze scene kunnen voorkomen?* <br/><span className={'small'}>(Meerdere antwoorden mogelijk)</span></span>,
        UK: <span>From what kind of genre film could this scene originate?* <br/><span className={"small"}>(Multiple answers allowed)</span></span>
    },
    plekinfilm: {
        Taal_ID: 55,
        Naam: "plekinfilm",
        NL: "Waar in de film zou u deze scene verwachten?*",
        UK: "Where in the film would you expect this scene?*"
    },
    plekinfilmunvalid: {
        Taal_ID: 56,
        Naam: "plekinfilmunvalid",
        NL: "Selecteer uw antwoord",
        UK: "Select your answer"
    },
    q11a: {
        Taal_ID: 57,
        Naam: "q11a",
        NL: "Openingsscene",
        UK: "Openingscene"
    },
    q11b: {
        Taal_ID: 58,
        Naam: "q11b",
        NL: "In eerste gedeelte",
        UK: "In the first part"
    },
    q11c: {
        Taal_ID: 59,
        Naam: "q11c",
        NL: "In het midden",
        UK: "In the middle"
    },
    q11d: {
        Taal_ID: 60,
        Naam: "q11d",
        NL: "In het laatste gedeelte",
        UK: "In the last part"
    },
    q11e: {
        Taal_ID: 61,
        Naam: "q11e",
        NL: "Slotscene",
        UK: "Closingscene"
    },
    q11f: {
        Taal_ID: 62,
        Naam: "q11f",
        NL: "Weet ik niet",
        UK: "I don&#39;t know"
    },
    actie: {
        Taal_ID: 63,
        Naam: "actie",
        NL: "Actie",
        UK: "Action"
    },
    avontuur: {
        Taal_ID: 64,
        Naam: "avontuur",
        NL: "Avontuur",
        UK: "Adventure"
    },
    comedy: {
        Taal_ID: 65,
        Naam: "comedy",
        NL: "Comedy",
        UK: "Comedy"
    },
    misdaad: {
        Taal_ID: 66,
        Naam: "misdaad",
        NL: "Misdaad",
        UK: "Crime"
    },
    documentaire: {
        Taal_ID: 67,
        Naam: "documentaire",
        NL: "Documentaire",
        UK: "Documentary"
    },
    drama: {
        Taal_ID: 68,
        Naam: "drama",
        NL: "Drama",
        UK: "Drama"
    },
    familie: {
        Taal_ID: 69,
        Naam: "familie",
        NL: "familie",
        UK: "Family"
    },
    fantasie: {
        Taal_ID: 70,
        Naam: "fantasie",
        NL: "Fantasie",
        UK: "Fantasy"
    },
    horror: {
        Taal_ID: 71,
        Naam: "horror",
        NL: "Horror",
        UK: "Horror"
    },
    musical: {
        Taal_ID: 72,
        Naam: "musical",
        NL: "Musical",
        UK: "Musical"
    },
    detectieve: {
        Taal_ID: 73,
        Naam: "detectieve",
        NL: "Detectieve",
        UK: "Mystery"
    },
    romantisch: {
        Taal_ID: 74,
        Naam: "romantisch",
        NL: "Romantisch",
        UK: "Romance"
    },
    sciencefiction: {
        Taal_ID: 75,
        Naam: "sciencefiction",
        NL: "Sciencefiction",
        UK: "Science Fiction"
    },
    sport: {
        Taal_ID: 76,
        Naam: "sport",
        NL: "Sport",
        UK: "Sport"
    },
    thriller: {
        Taal_ID: 77,
        Naam: "thriller",
        NL: "Thriller",
        UK: "Thriller"
    },
    oorlog: {
        Taal_ID: 78,
        Naam: "oorlog",
        NL: "Oorlog",
        UK: "War"
    },
    western: {
        Taal_ID: 79,
        Naam: "western",
        NL: "Western",
        UK: "Western"
    },
    instructie: {
        Taal_ID: 80,
        Naam: "instructie",
        NL: <span><p><span className={"small"}>Het volgende wordt ook in het bovenstaande filmpje verteld</span></p><p>Hallo. Wij zijn Jan de Mooij en Dick van Baardewijk. Voor school doen wij een onderzoek naar de gevoelens die filmfragmenten oproepen. </p> <p>Op deze pagina vindt u een enqu&#234;te waarin wij aan de hand van vier filmpjes een aantal vragen stellen. Wij laten u telkens een filmpje zien, hierna stellen we een aantal vragen en zodra deze zijn ingevuld kunt u verder met het volgende filmpje. Wij vragen u om per filmpje aan te geven hoe sterk de genoemde emoties bij u aanwezig waren: </p> <p>Zo kan het bijvoorbeeld zijn dat een fragment razend spannend aanvoelt, dan vult achter <i>spannend</i> een 5 in. Het kan ook zo zijn dat u totaal geen blijdschap voelt. Als dat het geval is vult u achter <i>blijdschap</i> een 1 in. Begrijpt u een vraag niet vul dan 3 in. Wij vragen u niet te lang na te denken over de vragen, dit kan de meetresultaten be&#239;nvloeden. Wij onderzoeken wat mensen voelen bij het kijken naar de filmfragmenten, er is dus geen goed of fout. </p> <p>Vervolgens vragen wij per fragment of u dit heeft herkend en wat er zich volgens u afspeelt in deze sc&#232;ne. Ook kunt u per filmpje nog op- of aanmerkingen invullen. </p> <p>Vooraf aan het onderzoek vragen we om uw leeftijd en geslacht. Om uw privacy te beschermen worden geen contactgegevens gevraagd. Heeft u vragen of opmerkingen dan kunt u contact met ons opnemen  <a href={"/survey/NL/end"} title="contact" target="_blank" rel="noreferrer">via deze link</a>.</p><p>Als laatste zou het ons heel erg helpen als u deze enqu&#234;te naar uw vrienden, familie en kennissenkring doorstuurt. Voor dit onderzoek is het belangrijk dat uw geluid aan staat. Alvast bedankt voor het meewerken aan ons onderzoek. </p></span>,
        UK: <span><p><span className={"small"}>The following is also explained in the film above</span></p><p>Hello. We are Jan de Mooij and Dick van Baardewijk. For our end term project we are researching the ambiance created by film scenes. </p> <p>On this site you find an inquiry with a few questions about four film scenes. We show you one scene at the time. After answering the matching questions, you can continue to the next scene. After each scene we ask you to indicate how strongly the listed feelings were present for you, while watching the scene:</p> <p>For example: when you feel a great tense while watching the scene you check the fifth option behind <i>tense</i>. When you don't feel any joy you check the first option behind <i>joy</i>. We ask you kindly not to think to long about the questions, this may influence the results. We just want to know what you feel while watching the scenes, so there is no right or wrong. </p> <p>Next we ask you if you know the scene and what, according to you, took place in it. Also, there is a possibility to give a remark for each scene.</p> <p>Before we show you the first scene we will ask for your age and gender. To protect your privacy we do not ask any contact information. If you have any questions or remarks you can contact us using <a href={"/survey/UK/end"} title="contact" target="_blank" rel="noreferrer">this link</a></p> <p>On a final note, we would like to ask you to forward this form to your friends and family. This would help us immensely. For this inquiry, it is important to enable sound. For now we thank you for helping us with our research. </p></span>
    },
    mineen: {
        Taal_ID: 81,
        Naam: "mineen",
        NL: "Er moet minimaal een genre geselecteerd worden.",
        UK: "At least one genre has to be selected"
    },
    prive: {
        Taal_ID: 82,
        Naam: "prive",
        NL: "Zeg ik liever niet",
        UK: "I rather don"
    },
    toggle: {
        Taal_ID: 84,
        Naam: "toggle",
        NL: "Laat instructies zien of verdwijnen",
        UK: "Toggle Instructions"
    },
    verplicht: {
        Taal_ID: 85,
        Naam: "verplicht",
        NL: <span className="small">Vragen met een sterretje (*) zijn verplicht.</span>,
        UK: <span className="small">Questions with an asterisk (*) are compulsory.</span>
    },
    series: {
        NL: "Enquêteserie",
        UK: "Survey series"
    },
    legend: {
        NL: "Legenda",
        UK: "Legend"
    },
    community_average: {
        NL: "Oorsponkelijke reacties",
        UK: "Average of original response"
    },
    your_average: {
        NL: "Uw reactie",
        UK: "Your response"
    },
    film: {
        NL: "Scènes uit",
        UK: "Scenes from"
    },
    version: {
        NL: "Versie",
        UK: "Version"
    },
    original: {
        NL: "Origineel",
        UK: "Original"
    },
    edited: {
        NL: "Geëdit",
        UK: "Edited"
    },
    usedmusic: {
        NL: "Score titel",
        UK: "Score title"
    },
    composernaam: {
        NL: "Componist",
        UK: "Composer"
    },
    album: {
        NL: "Album",
        UK: "Album"
    },
    musicsource: {
        NL: "Oorsponkelijk verschenen in",
        UK: "Originally appeared in",
    },
    filmpjes: {
        NL: "Filmfragmenten",
        UK: "Film fragments"
    },
    survey: {
        NL: "Enquête",
        UK: "Survey"
    },
    gastenboek: {
        NL: "Gastenboek",
        UK: "Guestbook"
    },
    by: {
        NL: "door",
        UK: "by"
    },
    researchtitle: {
        NL: "Invloed van Muziek op Film",
        UK: "Influence of Music on Film"
    },
    downloadrapport: {
        NL: "Download het rapport",
        UK: "Download the paper"
    },
    bettercompareavailable: {
        NL: <div>
            <p>Deze pagina was onze originele poging de verschillende variaties tussen de vier gebruikte filmfragmenten
                te tonen. Tijdens het herstellen van deze webpagina hebben wij een verbeterd overzicht van deze fragmenten
                ontwikkeld, welke <Link to={"/NL/compare/"}>hier</Link> te vinden is. Op die pagina zijn ook de enquêteresultaten
                per filmpje te zien</p>
            <p>Deze pagina is beschikbaar gehouden voor authenticiteit</p>
        </div>,
        UK: <div>
            <p>This following page was our original attempt at showing the different variations of the four film fragments
            we have used. In the process of restoring this web page, we have developed a better overview of these fragments,
            which you can find <Link to={"/UK/compare/"}>here</Link>. On that page, it is also possible to see the survey
            results related to each video.</p>
            <p>The rest of the page has been kept available for authenticity</p>
        </div>
    },
    nolongercollectingdata: {
        NL: <div>
            <p>Let op! Dit onderzoek is afgerond, en resultaten zijn reeds verwerkt!</p>
            <p>Uw antwoorden worden alleen opgeslagen in uw browser en zullen niet met ons worden gedeeld of worden verwerkt.
                U kunt de enquête nog steeds doen om een sfeerimpressie
            te krijgen van hoe de enquete oorspronkelijk in elkaar zat, en u kunt uw antwoorden vergelijken met het gemeten
            gemiddelde, maar uw antwoorden worden niet meer verwerkt voor dit onderzoek</p>
        </div>,
        UK: <div>
            <p>Please notice! This research has ended, and results have already been processed!</p>
            <p>Your response to this survey will only be stored in your browser and will not be shared with us or processed.
                You will still be able to do the survey
            to get an impression of how it originally worked, and you will be able to compare your response with the
            measured average, but your response will not be processed for this research.</p>
        </div>
    },
    404: {
        NL: "Deze pagina bestaat niet",
        UK: "This page does not exist"
    },
    "mailforminactive" : {
        NL: <span>
            <h3>Herhaling, let op!</h3>
            <p>Deze enquête is een statische replica van de oorspronkelijke enquête waarmee onderzoeksdata werd
            verzameld</p>
            <p><em>Uw antwoorden worden alleen opgeslagen op uw apparaat en niet verwerkt</em>. Ook het contactformulier en het
            contactemailadres werken niet langer.</p>
            <p>Uw antwoorden worden alleen bewaard in uw browser. Wij verzamelen en verwerken geen van
            de antwoorden die nu nog worden gegeven op deze enquête.</p>
            <p>U kunt daar <Link to={"/NL/"} target={"_blank"} rel={"noreferrer"}>hier</Link> meer over lezen.</p>
        </span>,
        UK: <span>
            <h3>Note again!</h3>
            <p>This survey is a static replication of the previous survey used for research.</p>
            <p><em>Responses are only saved locally on your device and are not being processed</em>.
                The contact form or contact e-mail are no longer operative.</p>
            <p>Your response is only kept inside your browser. We no longer collect or process these responses.</p>
            <p>You can find out more <Link to={'/UK/'} target={"_blank"} rel={"noreferrer"}>here</Link></p>
        </span>
    },
    "gotoresults" : {
        NL: (link) => <span>
            <p>Indien u geïnteresseerd bent kunt u {link} uw antwoorden vergelijken met het gemiddelde antwoord dat werd
            gegeven tijdens de oorspronkelijke onderzoeksveride .</p>
            <p>Voor dit onderzoek zijn u vier scenes van verschillende films getoond. Twee van deze scenes waren
            origineel, terwijl de andere twee aangepast waren om andere scores (filmmuziek of achtergrondmuziek) te gebruiken.
            Er bestaan nog twee andere series met dezelfde scenes maar met verschillende scores, die u {link} kan vergelijken. </p>
            <p>Deze informatiebubbel was niet onderdeel van de oorspronkelijke enquête</p>
        </span>,
        UK: (link) => <span><p>If you are interested, you can compare your responses with the average values obtained during the
                original research&nbsp;
            {link}
            </p>
            <p>For this research, you have been presented with four scenes from varying films. Two of those were original,
            two others have been edited with alternative scores (background music). Two other series of the same
            videos exist with differently edited scores, which you can compare {link}.</p>
            <p><small>This information bubble was not present in the original survey</small></p>
            </span>
    },
    "nomailsent" : {
        NL: "E-mail is NIET verzonden. U heeft de enquête ervaring succesvol voltooid! Lees meer in de informatiebubbel onderaan de pagina.",
        UK: "E-mail has NOT been sent. You have finished the survey experience! Read more in the information bubble at the bottom of this page."
    },
    here: {
        NL: "hier",
        UK: "here"
    },
    methodHeader: {
        NL: "Methode",
        UK: "Method"
    },
    method: {
        NL: <div>
            <p>Hieronder ziet u drie verschillende versies van vier verschillende filmfragmenten.</p>
            <p>
                Van elk filmfragment gebruikt één versie de filmmuziek die in de film waar het fragment uit
                komt ook werd gebruikt. Deze clip wordt aangeduid als <em>"origineel"</em>.
                In de andere twee fragmenten is de muziek vervangen met andere filmmuziek. Deze fragmenten worden
                aangeduid als <em>"geëdit"</em>.
            </p>
            <p>
                Tijdens dit proces kwamen wij er snel achter dat door al het geluid van het fragment integraal te
                vervangen door nieuwe filmmuziek, het beeld niet meer bij het geluid lijkt te horen en het fragment
                meer lijkt op een muziekvideo dan een film fragment. Hierdoor leerden wij voor het eerst
                over <a href={"https://en.wikipedia.org/wiki/Foley_(filmmaking)"} target={"_blank"} rel={"noreferrer"}>Foley</a> geluidseffecten.
                Dit is waarom we voor elk film fragment het complete geluid, inclusief geluiidseffecten, hebben proberen
                te reproduceren. Vanhieruit hebben wij de drie verschillende genoemde versies van elk fragment gemaakt,
                met onze eigen (voor elk fragment identieke) geluidseffecten. Technisch gezien zijn de fragmenten
                gemarkeerd als <em>origineel</em> dus ook gëedit! Deze fragmenten dienden als de nulmeting in ons onderzoek.
            </p>
            <p>
                De fragmenten zijn verdeeld over drie <em>vraagseries</em> zodat elke serie één fragment uit elk van
                de vier gebruikte films bevat, waarvan één of twee fragmenten de originele filmmuziek gebruiken en
                de rest afwijkende muziek gebruikt.
            </p>
            <p>
                Om te voorkomen dat participanten te snel doorkregen dat er iets geks aan de hand was met de geëditte
                fragmenten werden in elke serie eerst de originele fragmenten gepresenteerd, gevolgd door de geëditte
                fragmenten, waarbij de fragmenten met de minst afwijkende sfeer eerder worden getoond dan die met
                sterker verschillende sfeer.
            </p>
            <p>
                De serie waar een fragment toe behoort staat hieronder ook aangegeven.
            </p>
            <p>
                Hieronder kun je de drie fragmenten van elke film met elkaar vergelijken en de gemiddelden van de
                antwoorden op de enquête zien (n = 163, dus elke serie had 54 of 55 participanten).
            </p>
        </div>,
        UK: <div>
            <p>Below, you can see three different versions of four film fragments.</p>
            <p>One of each of the three clips uses the original film music as it was shown in the original movie.
            This clip is indicated as being the <em>"original"</em>. In the other two clips, the original music has
                been replaced with different music, indicated as being <em>"edited"</em>.</p>
            <p>However, as we quickly found out
            substituting all sound with different music would detach the video from the audio, making it look more like
                a music video than a film fragment. This taught us for the
            first time about <a href={"https://en.wikipedia.org/wiki/Foley_(filmmaking)"} target={"_blank"} rel={"noreferrer"}>Foley</a> sound effects.
            For this reason, we have attempted to recreate the sound stage for each film fragment used, and created the
                three mentioned different versions of background music using that sound stage. For this reason, even
                the clips marked <em>"original"</em> have technically been edited. However, they served as the baseline
                measurement in our research.
            </p>
            <p>The fragments have been distributed over three <em>survey series</em>, such that each series contained
            one clip from each of the four films we have used, one or two of which appeared with their original music and
            the rest being edited.</p>
            <p>To avoid participants noticing something strange, each series first presented the
                original unedited clips, followed by the edited clips with increasing change in ambiance of the music
            compared to the original.</p>
            <p>The series a clip was assigned is also indicated below.</p>
            <p>Below, you can compare the three fragments for each film side by side, and see the average of the
            response to the survey (n = 163, so each series had between 54 and 55 participants).</p>
        </div>
    },
    methodHintDoSurvey: {
        NL: <p>U kunt ook zelf de enquête doorlopen, waarbij u op het eind een link naar deze pagina gepresenteerd wordt,
        die u in staat stelt uw gegeven antwoorden te vergelijken met dit gemiddelde.</p>,
        UK: <p>You can also do the survey, in which case you will be given a link to this page at the end, that allows you to
            compare your results with this average.</p>
    },
    methodSurveyFinished: {
        NL: <p>Omdat u de enquête reeds heeft gedaan, kunt u uw gegeven antwoorden hieronder vergelijken met dit gemiddelde
        voor de enquêteserie die u heeft ingevuld.</p>,
        UK: <p>Because you have already finished the survey, you can also compare your given answers to this average for
        the series you were presented in the survey below.</p>
    }
}
export default language_strings;
