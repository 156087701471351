import LanguageService from "../../services/languageService";
import {useRef, useState} from "react";
import {Link} from "react-router-dom";

function End(props) {

    const [submitted, setSubmitted] = useState(false);
    const name = useRef();
    const mail = useRef();
    const country = useRef();
    const message = useRef();

    return <div className="End">
        <div id="center">
            {LanguageService.translate('dank')}
        </div>
        {LanguageService.translate('verplicht')}
        <br/>
        {submitted && (name?.current?.value.trim() === '' || !country.current?.value.trim() || message.current?.value.trim() === '') && <font color={"#ff0000"}>{LanguageService.translate('oningevuld')}</font>}
        <table className={"styled"}>
            <tbody>
                <tr className={submitted && name?.current?.value.trim() === '' ? 'error' : ''}>
                    <td width={200}>
                        <label className={"maillabel"}>{LanguageService.translate('mailnaam')}</label>
                    </td>
                    <td><input type={'text'} name={"mailname"} ref={name} onChange={() => setSubmitted(false)}/></td>
                </tr>
                    <tr>
                        <td width='150px'>
                            <label className='maillabel'>{LanguageService.translate("mailemail")}:</label>
                        </td>
                        <td>
                            <input type='text' name='mailmail' ref={mail} onChange={() => setSubmitted(false)}/>
                        </td>
                    </tr>
                    <tr className={submitted && !country?.current?.value? 'error' : ''}>
                        <td width='150px'>
                            <label className='maillabel'>{LanguageService.translate("mailnationaliteit")}:</label>
                        </td>

                        <td>
                            <select name={"mailnationaliteit"} ref={country} onChange={() => setSubmitted(false)}>
                                {LanguageService.getNationalities().map(nationality =>
                                    <option key={nationality.key} value={nationality.value}>{nationality.label}</option>
                                )}
                            </select>
                        </td>
                    </tr>
                    <tr className={submitted && message?.current?.value.trim() === '' ? 'error' : ''}>
                        <td width={150}><label className={'maillabel'}>{LanguageService.translate('mailopmerking')}:</label></td>
                        <td><textarea name={'mailcomment'} rows={10} cols={65} ref={message} onChange={() => setSubmitted(false)}/></td>
                    </tr>
            </tbody>
        </table>
        <input type={'submit'} name={'versturen'} value={LanguageService.translate('versturen')} onClick={() => {setSubmitted(true);
            alert(LanguageService.translate('nomailsent'));}}
        />
        <div className={"infobubble"}>
            {LanguageService.translate("mailforminactive")}
            {Object.keys(props.response).length > 0 && LanguageService.translate("gotoresults")(<Link to={`/${LanguageService.lang}/compare`} state={{response: props.response, series: props.serie}}>
                {LanguageService.translate('here')}
            </Link>)}
        </div>
    </div>
}

export default End;
