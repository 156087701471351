import './App.css';
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import Survey from "./survey/survey";
import Home from "./home/home";
import {useEffect, useState} from "react";
import language_strings from "../data/Languages";
import LanguageService from "../services/languageService";
import SurveyResponseService from "../services/surveyResponseService";

export default function App(props) {
    const [vraagserie] = useState(SurveyResponseService.getRandomSeries());

    const sampleResponse = {"F5Q1":1,"F5Q2":2,"F5Q3":3,"F5Q4":4,"F5Q5":5,"F5Q6":4,"F5Q7":3,"F5Q8":2,"F5Q9":1,"F5Q10":"Action = no;<br/>\nAdventure = no;<br/>\nComedy = yes;<br/>\nCrime = no;<br/>\nDocumentary = no;<br/>\nDrama = no;<br/>\nFamily = no;<br/>\nFantasy = no;<br/>\nHorror = no;<br/>\nMusical = no;<br/>\nMystery = no;<br/>\nRomance = no;<br/>\nScience Fiction = no;<br/>\nSport = no;<br/>\nThriller = no;<br/>\nWar = no;<br/>\nWestern = no;<br/>\n","F5Q11":1,"F5Q12":2,"F5Q13":"ga","F0Q14":"","F6Q1":5,"F6Q2":1,"F6Q3":5,"F6Q4":1,"F6Q5":2,"F6Q6":3,"F6Q7":4,"F6Q8":5,"F6Q9":1,"F6Q10":"Action = no;<br/>\nAdventure = no;<br/>\nComedy = no;<br/>\nCrime = no;<br/>\nDocumentary = yes;<br/>\nDrama = no;<br/>\nFamily = no;<br/>\nFantasy = no;<br/>\nHorror = no;<br/>\nMusical = no;<br/>\nMystery = no;<br/>\nRomance = no;<br/>\nScience Fiction = no;<br/>\nSport = no;<br/>\nThriller = no;<br/>\nWar = no;<br/>\nWestern = no;<br/>\n","F6Q11":2,"F6Q12":2,"F6Q13":"tes","F1Q14":"","F7Q1":2,"F7Q2":3,"F7Q3":2,"F7Q4":3,"F7Q5":2,"F7Q6":3,"F7Q7":4,"F7Q8":5,"F7Q9":5,"F7Q10":"Action = no;<br/>\nAdventure = no;<br/>\nComedy = no;<br/>\nCrime = yes;<br/>\nDocumentary = no;<br/>\nDrama = no;<br/>\nFamily = no;<br/>\nFantasy = no;<br/>\nHorror = no;<br/>\nMusical = no;<br/>\nMystery = no;<br/>\nRomance = no;<br/>\nScience Fiction = no;<br/>\nSport = no;<br/>\nThriller = no;<br/>\nWar = no;<br/>\nWestern = no;<br/>\n","F7Q11":3,"F7Q12":2,"F7Q13":"te","F2Q14":"","F8Q1":3,"F8Q2":3,"F8Q3":3,"F8Q4":3,"F8Q5":3,"F8Q6":3,"F8Q7":3,"F8Q8":3,"F8Q9":3,"F8Q10":"Action = no;<br/>\nAdventure = yes;<br/>\nComedy = no;<br/>\nCrime = no;<br/>\nDocumentary = no;<br/>\nDrama = no;<br/>\nFamily = no;<br/>\nFantasy = no;<br/>\nHorror = no;<br/>\nMusical = no;<br/>\nMystery = no;<br/>\nRomance = no;<br/>\nScience Fiction = no;<br/>\nSport = no;<br/>\nThriller = no;<br/>\nWar = no;<br/>\nWestern = no;<br/>\n","F8Q11":2,"F8Q12":2,"F8Q13":"test","F3Q14":""}
    const location = useLocation();

    const pages = [
        {to: '', title: 'Home'},
        {to: 'download', title: 'Download'},
        {to: 'videos', title: LanguageService.translate('filmpjes')},
        {to: 'survey', title: LanguageService.translate('survey'), blank: true},
    ];

    useEffect(() => {
        document.title = language_strings["title"][LanguageService.lang || "UK"];
    }, [location])

    return (
        <Routes>
            <Route exact path="survey" element={
                <Survey
                />} />
            <Route exact path="survey/:lang" element={
                <Survey
                />} />
            <Route exact path={"survey/:lang/end"} element={
                <Survey showEnd={true} />}
            />
            <Route path="UK/*" element={<Home lang={"UK"} pages={pages} />}/>
            <Route path="NL/*" element={<Home lang={"NL"} pages={pages} />}/>
            <Route path="*" element={<Navigate replace to={`/UK${location.pathname}`}/>}/>
        </Routes>
    );

}


