
const quotes = [
    {
        quote: "Star Wars wouldn't be the same without his music I actually bought the single of the theme at Kmart when I was a kid.",
        attribution: "Roy McDonalds"
    },
    {
        quote: "That is the great power of source music – music that comes within the scene, either because an orchestra is playing and you see the orchestra or because somebody has a radio or record player on. It has a musical effect on the audience, but they are insulated from feeling overly manipulated musically because the sounds are explained by the scene. It seems almost accidental: Oh! This music just happened to be playing while they were filming the scene: it isn’t read as having anything to do with the subtext of the film. Of course, it does. But your conscious impression is that it doesn’t. Sound effects work very much the same way",
        attribution: "Walter Murch"
    },
    {
        quote: "He made a score that in some ways is very spare, which fits with the period that is represented in the film, which is actually a unique period in Japanese history because it was a period of transition. Hence, you have all these instruments in transition as well",
        attribution: "Yo-Yo Ma"
    },
    {
        quote: "Film music is no more than wallpaper, filler material",
        attribution: "Igor Stravinsky"
    },
    {
        quote: "We zien een postkoets door een Noord-Amerikaans landschap denderen. Achtervolgd door een dozijn cowboys die op hun beurt achterna gezeten worden door 25 brullende geverfde roodhuiden. In hun slipstream een cameraman op een witte schimmel. Naast een parallel galopperende mustang waarop een geluidsman met een microhengel. Daarachter zien wij 65 paarden met jachtig spelende orkestleden. We weten nu hoe filmmuziek gemaakt wordt.",
        attribution: "Herman van Veen (Soundtrack, In vogelvlucht)"
    },
    {
        quote: "Music can make it sore.",
        attribution: "Mel Gibson"
    },
    {
        quote: "A piece of music is never finished; It is at most left alone",
        attribution: "John Williams"
    },
    {
        quote: "The operative word in music is ‘play’. You can either be a musician, or you can grow up. You can’t do both.",
        attribution: "Hans Zimmer"
    },
    {
        quote: "A painter paints his pictures on canvas. But musicians paint their pictures on silence.",
        attribution: "Leopold Stowoski"
    },
    {
        quote: "Pick up a camera. Shoot something. No matter how small, no matter how cheesy, no matter whether your friends and your sister star in it. Put your name on it as director. Now you're a director. Everything after that you're just negotiating your budget and your fee.",
        attribution: "James Cameron"
    },
    {
        quote: "I love acting. It’s so much more real than life",
        attribution: "Oscar Wilde"
    },
    {
        quote: "Film editing is now something almost everyone can do at a simple level and enjoy it, but to take it to a higher level requires the same dedication and persistence that any art form does.",
        attribution: "Walter Murch"
    },
    {
        quote: "You got one guy going boom, one guy going whack, and one guy not getting in the endzone.",
        attribution: "John Madden"
    },
]

const QuoteService = {
    getQuote: () => {
        const index = Math.round(Math.random() * (quotes.length - 1));
        return quotes[index];
    }
}

export default QuoteService;
