const nationalities = {
    "NL": {
        "id": 157,
        "suffix": "NL",
        UK: <span>Netherlands</span>,
        NL: <span>Nederland</span>
    },
    "UK": {
        "id": 236,
        "suffix": "UK",
        UK: <span>United Kingdom</span>,
        NL: <span>Verenigd Koninkrijk</span>
    },
    "US": {
        "id": 237,
        "suffix": "US",
        UK: <span>United States</span>,
        NL: <span>Verenigde Staten</span>
    },
    "FR": {
        "id": 77,
        "suffix": "FR",
        UK: <span>France</span>,
        NL: <span>Frankrijk</span>
    },
    "AU": {
        "id": 5,
        "suffix": "AU",
        UK: <span>Australia</span>,
        NL: <span>Australi&euml;</span>
    },
    "AX": {
        "id": 6,
        "suffix": "AX",
        UK: <span>&#197;land Islands</span>,
        NL: <span>&#197;land&#45;eilanden</span>
    },
    "AF": {
        "id": 7,
        "suffix": "AF",
        UK: <span>Afghanistan</span>,
        NL: <span>Afghanistan</span>
    },
    "AL": {
        "id": 8,
        "suffix": "AL",
        UK: <span>Albania</span>,
        NL: <span>Albani&euml;</span>
    },
    "DZ": {
        "id": 9,
        "suffix": "DZ",
        UK: <span>Algeria</span>,
        NL: <span>Algerije</span>
    },
    "AS": {
        "id": 10,
        "suffix": "AS",
        UK: <span>American Samoa</span>,
        NL: <span>Amerikaans&#45;Samoa</span>
    },
    "VI": {
        "id": 11,
        "suffix": "VI",
        UK: <span>US Virgin Islands</span>,
        NL: <span>Amerikaanse Maagdeneilanden</span>
    },
    "UM": {
        "id": 12,
        "suffix": "UM",
        UK: <span>United States Minor Outlying Islands</span>,
        NL: <span>Amerikaanse ondergeschikte afgelegen eilanden</span>
    },
    "AD": {
        "id": 13,
        "suffix": "AD",
        UK: <span>Andorra</span>,
        NL: <span>Andorra</span>
    },
    "AO": {
        "id": 14,
        "suffix": "AO",
        UK: <span>Angola</span>,
        NL: <span>Angola</span>
    },
    "AI": {
        "id": 15,
        "suffix": "AI",
        UK: <span>Anguilla</span>,
        NL: <span>Anguilla</span>
    },
    "AQ": {
        "id": 16,
        "suffix": "AQ",
        UK: <span>Antarctica</span>,
        NL: <span>Antarctica</span>
    },
    "AG": {
        "id": 17,
        "suffix": "AG",
        UK: <span>Antigua and Barbuda</span>,
        NL: <span>Antigua en Barbuda</span>
    },
    "AR": {
        "id": 18,
        "suffix": "AR",
        UK: <span>Argentina</span>,
        NL: <span>Argentini&euml;</span>
    },
    "AM": {
        "id": 19,
        "suffix": "AM",
        UK: <span>Armenia</span>,
        NL: <span>Armeni&euml;</span>
    },
    "AW": {
        "id": 20,
        "suffix": "AW",
        UK: <span>Aruba</span>,
        NL: <span>Aruba</span>
    },
    "AZ": {
        "id": 21,
        "suffix": "AZ",
        UK: <span>Azerbaijan</span>,
        NL: <span>Azerbeidzjan</span>
    },
    "BS": {
        "id": 22,
        "suffix": "BS",
        UK: <span>Bahamas</span>,
        NL: <span>Bahamas</span>
    },
    "BH": {
        "id": 23,
        "suffix": "BH",
        UK: <span>Bahrain</span>,
        NL: <span>Bahrein</span>
    },
    "BD": {
        "id": 24,
        "suffix": "BD",
        UK: <span>Bangladesh</span>,
        NL: <span>Bangladesh</span>
    },
    "BB": {
        "id": 25,
        "suffix": "BB",
        UK: <span>Barbados</span>,
        NL: <span>Barbados</span>
    },
    "BE": {
        "id": 26,
        "suffix": "BE",
        UK: <span>Belgium</span>,
        NL: <span>Belgi&euml;</span>
    },
    "BZ": {
        "id": 27,
        "suffix": "BZ",
        UK: <span>Belize</span>,
        NL: <span>Belize</span>
    },
    "BJ": {
        "id": 28,
        "suffix": "BJ",
        UK: <span>Benin</span>,
        NL: <span>Benin</span>
    },
    "BM": {
        "id": 29,
        "suffix": "BM",
        UK: <span>Bermuda</span>,
        NL: <span>Bermuda</span>
    },
    "BT": {
        "id": 30,
        "suffix": "BT",
        UK: <span>Bhutan</span>,
        NL: <span>Bhutan</span>
    },
    "BI": {
        "id": 31,
        "suffix": "BI",
        UK: <span>Burundi</span>,
        NL: <span>Boeroendi</span>
    },
    "BO": {
        "id": 32,
        "suffix": "BO",
        UK: <span>Bolivia</span>,
        NL: <span>Bolivia</span>
    },
    "BA": {
        "id": 33,
        "suffix": "BA",
        UK: <span>Bosnia and Herzegovina</span>,
        NL: <span>Bosni&euml; en Herzegovina</span>
    },
    "BW": {
        "id": 34,
        "suffix": "BW",
        UK: <span>Botswana</span>,
        NL: <span>Botswana</span>
    },
    "BV": {
        "id": 35,
        "suffix": "BV",
        UK: <span>Bouvet Island</span>,
        NL: <span>Bouvet Island</span>
    },
    "BR": {
        "id": 36,
        "suffix": "BR",
        UK: <span>Brazil</span>,
        NL: <span>Brazili&euml;</span>
    },
    "IO": {
        "id": 37,
        "suffix": "IO",
        UK: <span>British Indian Ocean Territory</span>,
        NL: <span>British Indian Ocean Territory</span>
    },
    "VG": {
        "id": 38,
        "suffix": "VG",
        UK: <span>British Virgin Islands</span>,
        NL: <span>British Virgin Islands</span>
    },
    "BN": {
        "id": 39,
        "suffix": "BN",
        UK: <span>Brunei</span>,
        NL: <span>Brunei</span>
    },
    "BG": {
        "id": 40,
        "suffix": "BG",
        UK: <span>Bulgaria</span>,
        NL: <span>Bulgarije</span>
    },
    "BF": {
        "id": 41,
        "suffix": "BF",
        UK: <span>Burkina Faso</span>,
        NL: <span>Burkina Faso</span>
    },
    "CI": {
        "id": 42,
        "suffix": "CI",
        UK: <span>C&ocirc;te d&#39;Ivoire</span>,
        NL: <span>C&ocirc;te d&#39;Ivoire</span>
    },
    "KH": {
        "id": 43,
        "suffix": "KH",
        UK: <span>Cambodia</span>,
        NL: <span>Cambodja</span>
    },
    "CA": {
        "id": 44,
        "suffix": "CA",
        UK: <span>Canada</span>,
        NL: <span>Canada</span>
    },
    "CV": {
        "id": 45,
        "suffix": "CV",
        UK: <span>Cape Verde</span>,
        NL: <span>Cape Verde</span>
    },
    "CF": {
        "id": 46,
        "suffix": "CF",
        UK: <span>Central African Republic</span>,
        NL: <span>Centraal&#45;Afrikaanse Republiek</span>
    },
    "CL": {
        "id": 47,
        "suffix": "CL",
        UK: <span>Chile</span>,
        NL: <span>Chili</span>
    },
    "CN": {
        "id": 48,
        "suffix": "CN",
        UK: <span>China</span>,
        NL: <span>China</span>
    },
    "CX": {
        "id": 49,
        "suffix": "CX",
        UK: <span>Christmas Island</span>,
        NL: <span>Christmas Island</span>
    },
    "CP": {
        "id": 50,
        "suffix": "CP",
        UK: <span>Clipperton</span>,
        NL: <span>Clipperton</span>
    },
    "CC": {
        "id": 51,
        "suffix": "CC",
        UK: <span>Cocos (Keeling) Islands</span>,
        NL: <span>Cocos (Keeling) Islands</span>
    },
    "CO": {
        "id": 52,
        "suffix": "CO",
        UK: <span>Colombia</span>,
        NL: <span>Colombia</span>
    },
    "KM": {
        "id": 53,
        "suffix": "KM",
        UK: <span>Comoros</span>,
        NL: <span>Comoren</span>
    },
    "CG": {
        "id": 54,
        "suffix": "CG",
        UK: <span>Congo</span>,
        NL: <span>Congo</span>
    },
    "CK": {
        "id": 55,
        "suffix": "CK",
        UK: <span>Cook Islands</span>,
        NL: <span>Cook Eilanden</span>
    },
    "CR": {
        "id": 56,
        "suffix": "CR",
        UK: <span>Costa Rica</span>,
        NL: <span>Costa Rica</span>
    },
    "CU": {
        "id": 57,
        "suffix": "CU",
        UK: <span>Cuba</span>,
        NL: <span>Cuba</span>
    },
    "CY": {
        "id": 58,
        "suffix": "CY",
        UK: <span>Cyprus</span>,
        NL: <span>Cyprus</span>
    },
    "CD": {
        "id": 59,
        "suffix": "CD",
        UK: <span>Democratic Republic of the Congo</span>,
        NL: <span>Democratische Republiek Congo</span>
    },
    "DK": {
        "id": 60,
        "suffix": "DK",
        UK: <span>Denmark</span>,
        NL: <span>Denemarken</span>
    },
    "DJ": {
        "id": 61,
        "suffix": "DJ",
        UK: <span>Djibouti</span>,
        NL: <span>Djibouti</span>
    },
    "DM": {
        "id": 62,
        "suffix": "DM",
        UK: <span>Dominica</span>,
        NL: <span>Dominica</span>
    },
    "DO": {
        "id": 63,
        "suffix": "DO",
        UK: <span>Dominican Republic</span>,
        NL: <span>Dominicaanse Republiek</span>
    },
    "DE": {
        "id": 64,
        "suffix": "DE",
        UK: <span>Germany</span>,
        NL: <span>Duitsland</span>
    },
    "EC": {
        "id": 65,
        "suffix": "EC",
        UK: <span>Ecuador</span>,
        NL: <span>Ecuador</span>
    },
    "EG": {
        "id": 66,
        "suffix": "EG",
        UK: <span>Egypt</span>,
        NL: <span>Egypte</span>
    },
    "SV": {
        "id": 67,
        "suffix": "SV",
        UK: <span>El Salvador</span>,
        NL: <span>El Salvador</span>
    },
    "GQ": {
        "id": 68,
        "suffix": "GQ",
        UK: <span>Equatorial Guinea</span>,
        NL: <span>Equatoriaal&#45;Guinea</span>
    },
    "ER": {
        "id": 69,
        "suffix": "ER",
        UK: <span>Eritrea</span>,
        NL: <span>Eritrea</span>
    },
    "EE": {
        "id": 70,
        "suffix": "EE",
        UK: <span>Estonia</span>,
        NL: <span>Estland</span>
    },
    "ET": {
        "id": 71,
        "suffix": "ET",
        UK: <span>Ethiopia</span>,
        NL: <span>Ethiopi&euml;</span>
    },
    "FO": {
        "id": 72,
        "suffix": "FO",
        UK: <span>Faeroe Islands</span>,
        NL: <span>Faer&ouml;er</span>
    },
    "FK": {
        "id": 73,
        "suffix": "FK",
        UK: <span>Falkland Islands</span>,
        NL: <span>Falkland Eilanden</span>
    },
    "FJ": {
        "id": 74,
        "suffix": "FJ",
        UK: <span>Fiji</span>,
        NL: <span>Fiji</span>
    },
    "PH": {
        "id": 75,
        "suffix": "PH",
        UK: <span>Philippines</span>,
        NL: <span>Filipijnen</span>
    },
    "FI": {
        "id": 76,
        "suffix": "FI",
        UK: <span>Finland</span>,
        NL: <span>Finland</span>
    },
    "GF": {
        "id": 78,
        "suffix": "GF",
        UK: <span>French Guiana</span>,
        NL: <span>Frans&#45;Guyana</span>
    },
    "PF": {
        "id": 79,
        "suffix": "PF",
        UK: <span>French Polynesia</span>,
        NL: <span>Frans&#45;Polynesi&euml;</span>
    },
    "TF": {
        "id": 80,
        "suffix": "TF",
        UK: <span>French Southern and Antarctic Lands</span>,
        NL: <span>Franse Zuidelijke en Antarctische Gebieden</span>
    },
    "GA": {
        "id": 81,
        "suffix": "GA",
        UK: <span>Gabon</span>,
        NL: <span>Gabon</span>
    },
    "GM": {
        "id": 82,
        "suffix": "GM",
        UK: <span>The Gambia</span>,
        NL: <span>Gambia</span>
    },
    "GE": {
        "id": 83,
        "suffix": "GE",
        UK: <span>Georgia</span>,
        NL: <span>Georgi&euml;</span>
    },
    "GH": {
        "id": 84,
        "suffix": "GH",
        UK: <span>Ghana</span>,
        NL: <span>Ghana</span>
    },
    "GI": {
        "id": 85,
        "suffix": "GI",
        UK: <span>Gibraltar</span>,
        NL: <span>Gibraltar</span>
    },
    "GD": {
        "id": 86,
        "suffix": "GD",
        UK: <span>Grenada</span>,
        NL: <span>Grenada</span>
    },
    "EL": {
        "id": 87,
        "suffix": "EL",
        UK: <span>Greece</span>,
        NL: <span>Griekenland</span>
    },
    "GL": {
        "id": 88,
        "suffix": "GL",
        UK: <span>Greenland</span>,
        NL: <span>Groenland</span>
    },
    "GP": {
        "id": 89,
        "suffix": "GP",
        UK: <span>Guadeloupe</span>,
        NL: <span>Guadeloupe</span>
    },
    "GU": {
        "id": 90,
        "suffix": "GU",
        UK: <span>Guam</span>,
        NL: <span>Guam</span>
    },
    "GT": {
        "id": 91,
        "suffix": "GT",
        UK: <span>Guatemala</span>,
        NL: <span>Guatemala</span>
    },
    "GG": {
        "id": 92,
        "suffix": "GG",
        UK: <span>Guernsey</span>,
        NL: <span>Guernsey</span>
    },
    "GN": {
        "id": 93,
        "suffix": "GN",
        UK: <span>Guinea</span>,
        NL: <span>Guinee</span>
    },
    "GW": {
        "id": 94,
        "suffix": "GW",
        UK: <span>Guinea&#45;Bissau</span>,
        NL: <span>Guinee&#45;Bissau</span>
    },
    "GY": {
        "id": 95,
        "suffix": "GY",
        UK: <span>Guyana</span>,
        NL: <span>Guyana</span>
    },
    "HT": {
        "id": 96,
        "suffix": "HT",
        UK: <span>Haiti</span>,
        NL: <span>Ha&iuml;ti</span>
    },
    "HM": {
        "id": 97,
        "suffix": "HM",
        UK: <span>Heard Island and McDonald Islands</span>,
        NL: <span>Heard en McDonald eilanden</span>
    },
    "VA": {
        "id": 98,
        "suffix": "VA",
        UK: <span>Holy See/Vatican City</span>,
        NL: <span>Heilige Stoel / Vaticaanstad</span>
    },
    "HN": {
        "id": 99,
        "suffix": "HN",
        UK: <span>Honduras</span>,
        NL: <span>Honduras</span>
    },
    "HK": {
        "id": 100,
        "suffix": "HK",
        UK: <span>Hong Kong</span>,
        NL: <span>Hong Kong</span>
    },
    "HU": {
        "id": 101,
        "suffix": "HU",
        UK: <span>Hungary</span>,
        NL: <span>Hongarije</span>
    },
    "IE": {
        "id": 102,
        "suffix": "IE",
        UK: <span>Ireland</span>,
        NL: <span>Ierland</span>
    },
    "IS": {
        "id": 103,
        "suffix": "IS",
        UK: <span>Iceland</span>,
        NL: <span>IJsland</span>
    },
    "IN": {
        "id": 104,
        "suffix": "IN",
        UK: <span>India</span>,
        NL: <span>India</span>
    },
    "ID": {
        "id": 105,
        "suffix": "ID",
        UK: <span>Indonesia</span>,
        NL: <span>Indonesi&euml;</span>
    },
    "IQ": {
        "id": 106,
        "suffix": "IQ",
        UK: <span>Iraq</span>,
        NL: <span>Irak</span>
    },
    "IR": {
        "id": 107,
        "suffix": "IR",
        UK: <span>Iran</span>,
        NL: <span>Iran</span>
    },
    "IM": {
        "id": 108,
        "suffix": "IM",
        UK: <span>Isle of Man</span>,
        NL: <span>Isle of Man</span>
    },
    "IL": {
        "id": 109,
        "suffix": "IL",
        UK: <span>Israel</span>,
        NL: <span>Isra&euml;l</span>
    },
    "IT": {
        "id": 110,
        "suffix": "IT",
        UK: <span>Italy</span>,
        NL: <span>Itali&euml;</span>
    },
    "JM": {
        "id": 111,
        "suffix": "JM",
        UK: <span>Jamaica</span>,
        NL: <span>Jamaica</span>
    },
    "JP": {
        "id": 112,
        "suffix": "JP",
        UK: <span>Japan</span>,
        NL: <span>Japan</span>
    },
    "YE": {
        "id": 113,
        "suffix": "YE",
        UK: <span>Yemen</span>,
        NL: <span>Jemen</span>
    },
    "JO": {
        "id": 114,
        "suffix": "JO",
        UK: <span>Jordan</span>,
        NL: <span>Jordani&euml;</span>
    },
    "KY": {
        "id": 115,
        "suffix": "KY",
        UK: <span>Cayman Islands</span>,
        NL: <span>Kaaimaneilanden</span>
    },
    "CM": {
        "id": 116,
        "suffix": "CM",
        UK: <span>Cameroon</span>,
        NL: <span>Kameroen</span>
    },
    "QA": {
        "id": 117,
        "suffix": "QA",
        UK: <span>Qatar</span>,
        NL: <span>Katar</span>
    },
    "KZ": {
        "id": 118,
        "suffix": "KZ",
        UK: <span>Kazakhstan</span>,
        NL: <span>Kazachstan</span>
    },
    "KE": {
        "id": 119,
        "suffix": "KE",
        UK: <span>Kenya</span>,
        NL: <span>Kenia</span>
    },
    "KG": {
        "id": 120,
        "suffix": "KG",
        UK: <span>Kyrgyzstan</span>,
        NL: <span>Kirgizi&euml;</span>
    },
    "KI": {
        "id": 121,
        "suffix": "KI",
        UK: <span>Kiribati</span>,
        NL: <span>Kiribati</span>
    },
    "KW": {
        "id": 122,
        "suffix": "KW",
        UK: <span>Kuwait</span>,
        NL: <span>Koeweit</span>
    },
    "HR": {
        "id": 123,
        "suffix": "HR",
        UK: <span>Croatia</span>,
        NL: <span>Kroati&euml;</span>
    },
    "LA": {
        "id": 124,
        "suffix": "LA",
        UK: <span>Laos</span>,
        NL: <span>Laos</span>
    },
    "LS": {
        "id": 125,
        "suffix": "LS",
        UK: <span>Lesotho</span>,
        NL: <span>Lesotho</span>
    },
    "LV": {
        "id": 126,
        "suffix": "LV",
        UK: <span>Latvia</span>,
        NL: <span>Letland</span>
    },
    "LB": {
        "id": 127,
        "suffix": "LB",
        UK: <span>Lebanon</span>,
        NL: <span>Libanon</span>
    },
    "LR": {
        "id": 128,
        "suffix": "LR",
        UK: <span>Liberia</span>,
        NL: <span>Liberia</span>
    },
    "LY": {
        "id": 129,
        "suffix": "LY",
        UK: <span>Libya</span>,
        NL: <span>Libi&euml;</span>
    },
    "LI": {
        "id": 130,
        "suffix": "LI",
        UK: <span>Liechtenstein</span>,
        NL: <span>Liechtenstein</span>
    },
    "LT": {
        "id": 131,
        "suffix": "LT",
        UK: <span>Lithuania</span>,
        NL: <span>Litouwen</span>
    },
    "LU": {
        "id": 132,
        "suffix": "LU",
        UK: <span>Luxembourg</span>,
        NL: <span>Luxemburg</span>
    },
    "MO": {
        "id": 133,
        "suffix": "MO",
        UK: <span>Macao</span>,
        NL: <span>Macao</span>
    },
    "MG": {
        "id": 134,
        "suffix": "MG",
        UK: <span>Madagascar</span>,
        NL: <span>Madagascar</span>
    },
    "MW": {
        "id": 135,
        "suffix": "MW",
        UK: <span>Malawi</span>,
        NL: <span>Malawi</span>
    },
    "MV": {
        "id": 136,
        "suffix": "MV",
        UK: <span>Maldives</span>,
        NL: <span>Maldiven</span>
    },
    "MY": {
        "id": 137,
        "suffix": "MY",
        UK: <span>Malaysia</span>,
        NL: <span>Maleisi&euml;</span>
    },
    "ML": {
        "id": 138,
        "suffix": "ML",
        UK: <span>Mali</span>,
        NL: <span>Mali</span>
    },
    "MT": {
        "id": 139,
        "suffix": "MT",
        UK: <span>Malta</span>,
        NL: <span>Malta</span>
    },
    "MA": {
        "id": 140,
        "suffix": "MA",
        UK: <span>Morocco</span>,
        NL: <span>Marokko</span>
    },
    "MH": {
        "id": 141,
        "suffix": "MH",
        UK: <span>Marshall Islands</span>,
        NL: <span>Marshall Eilanden</span>
    },
    "MQ": {
        "id": 142,
        "suffix": "MQ",
        UK: <span>Martinique</span>,
        NL: <span>Martinique</span>
    },
    "MR": {
        "id": 143,
        "suffix": "MR",
        UK: <span>Mauritania</span>,
        NL: <span>Mauritani&euml;</span>
    },
    "MU": {
        "id": 144,
        "suffix": "MU",
        UK: <span>Mauritius</span>,
        NL: <span>Mauritius</span>
    },
    "YT": {
        "id": 145,
        "suffix": "YT",
        UK: <span>Mayotte</span>,
        NL: <span>Mayotte</span>
    },
    "MX": {
        "id": 146,
        "suffix": "MX",
        UK: <span>Mexico</span>,
        NL: <span>Mexico</span>
    },
    "FM": {
        "id": 147,
        "suffix": "FM",
        UK: <span>Micronesia</span>,
        NL: <span>Micronesi&euml;</span>
    },
    "MD": {
        "id": 148,
        "suffix": "MD",
        UK: <span>Moldova</span>,
        "NL": <span>Moldavi&euml;</span>
    },
    "MC": {
        "id": 149,
        "suffix": "MC",
        UK: <span>Monaco</span>,
        NL: <span>Monaco</span>
    },
    "MN": {
        "id": 150,
        "suffix": "MN",
        UK: <span>Mongolia</span>,
        NL: <span>Mongoli&euml;</span>
    },
    "ME": {
        "id": 151,
        "suffix": "ME",
        UK: <span>Montenegro</span>,
        NL: <span>Montenegro</span>
    },
    "MS": {
        "id": 152,
        "suffix": "MS",
        UK: <span>Montserrat</span>,
        NL: <span>Montserrat</span>
    },
    "MZ": {
        "id": 153,
        "suffix": "MZ",
        UK: <span>Mozambique</span>,
        NL: <span>Mozambique</span>
    },
    "MM": {
        "id": 154,
        "suffix": "MM",
        UK: <span>Myanmar</span>,
        NL: <span>Myanmar</span>
    },
    "NA": {
        "id": 155,
        "suffix": "NA",
        UK: <span>Namibia</span>,
        NL: <span>Namibi&euml;</span>
    },
    "NR": {
        "id": 156,
        "suffix": "NR",
        UK: <span>Nauru</span>,
        NL: <span>Nauru</span>
    },
    "AN": {
        "id": 158,
        "suffix": "AN",
        UK: <span>Netherlands Antilles</span>,
        NL: <span>Nederlandse Antillen</span>
    },
    "NP": {
        "id": 159,
        "suffix": "NP",
        UK: <span>Nepal</span>,
        NL: <span>Nepal</span>
    },
    "NI": {
        "id": 160,
        "suffix": "NI",
        UK: <span>Nicaragua</span>,
        NL: <span>Nicaragua</span>
    },
    "NC": {
        "id": 161,
        "suffix": "NC",
        UK: <span>New Caledonia</span>,
        NL: <span>Nieuw&#45;Caledoni&euml;</span>
    },
    "NZ": {
        "id": 162,
        "suffix": "NZ",
        UK: <span>New Zealand</span>,
        NL: <span>Nieuw&#45;Zeeland</span>
    },
    "NE": {
        "id": 163,
        "suffix": "NE",
        UK: <span>Niger</span>,
        NL: <span>Niger</span>
    },
    "NG": {
        "id": 164,
        "suffix": "NG",
        UK: <span>Nigeria</span>,
        NL: <span>Nigeria</span>
    },
    "NU": {
        "id": 165,
        "suffix": "NU",
        UK: <span>Niue</span>,
        NL: <span>Niue</span>
    },
    "KP": {
        "id": 166,
        "suffix": "KP",
        UK: <span>North Korea</span>,
        NL: <span>Noord&#45;Korea</span>
    },
    "MP": {
        "id": 167,
        "suffix": "MP",
        UK: <span>Northern Marianas</span>,
        NL: <span>Noordelijke Marianen</span>
    },
    "NO": {
        "id": 168,
        "suffix": "NO",
        UK: <span>Norway</span>,
        NL: <span>Noorwegen</span>
    },
    "NF": {
        "id": 169,
        "suffix": "NF",
        UK: <span>Norfolk Island</span>,
        NL: <span>Norfolk Island</span>
    },
    "UG": {
        "id": 170,
        "suffix": "UG",
        UK: <span>Uganda</span>,
        NL: <span>Oeganda</span>
    },
    "UA": {
        "id": 171,
        "suffix": "UA",
        UK: <span>Ukraine</span>,
        NL: <span>Oekra&iuml;ne</span>
    },
    "UZ": {
        "id": 172,
        "suffix": "UZ",
        UK: <span>Uzbekistan</span>,
        NL: <span>Oezbekistan</span>
    },
    "OM": {
        "id": 173,
        "suffix": "OM",
        UK: <span>Oman</span>,
        NL: <span>Oman</span>
    },
    "TL": {
        "id": 174,
        "suffix": "TL",
        UK: <span>East Timor</span>,
        NL: <span>Oost&#45;Timor</span>
    },
    "AT": {
        "id": 175,
        "suffix": "AT",
        UK: <span>Austria</span>,
        NL: <span>Oostenrijk</span>
    },
    "PK": {
        "id": 176,
        "suffix": "PK",
        UK: <span>Pakistan</span>,
        NL: <span>Pakistan</span>
    },
    "PW": {
        "id": 177,
        "suffix": "PW",
        UK: <span>Palau</span>,
        NL: <span>Palau</span>
    },
    "PA": {
        "id": 178,
        "suffix": "PA",
        UK: <span>Panama</span>,
        NL: <span>Panama</span>
    },
    "PG": {
        "id": 179,
        "suffix": "PG",
        UK: <span>Papua New Guinea</span>,
        NL: <span>Papoea&#45;Nieuw&#45;Guinea</span>
    },
    "PY": {
        "id": 180,
        "suffix": "PY",
        UK: <span>Paraguay</span>,
        NL: <span>Paraguay</span>
    },
    "PE": {
        "id": 181,
        "suffix": "PE",
        UK: <span>Peru</span>,
        NL: <span>Peru</span>
    },
    "PN": {
        "id": 182,
        "suffix": "PN",
        UK: <span>Pitcairn Islands</span>,
        NL: <span>Pitcairn Islands</span>
    },
    "PL": {
        "id": 183,
        "suffix": "PL",
        UK: <span>Poland</span>,
        NL: <span>Polen</span>
    },
    "PT": {
        "id": 184,
        "suffix": "PT",
        UK: <span>Portugal</span>,
        NL: <span>Portugal</span>
    },
    "PR": {
        "id": 185,
        "suffix": "PR",
        UK: <span>Puerto Rico</span>,
        NL: <span>Puerto Rico</span>
    },
    "RE": {
        "id": 186,
        "suffix": "RE",
        UK: <span>R&#233;union</span>,
        NL: <span>Reunion</span>
    },
    "RO": {
        "id": 187,
        "suffix": "RO",
        UK: <span>Romania</span>,
        NL: <span>Roemeni&euml;</span>
    },
    "RU": {
        "id": 188,
        "suffix": "RU",
        UK: <span>Russia</span>,
        NL: <span>Rusland</span>
    },
    "RW": {
        "id": 189,
        "suffix": "RW",
        UK: <span>Rwanda</span>,
        NL: <span>Rwanda</span>
    },
    "ST": {
        "id": 190,
        "suffix": "ST",
        UK: <span>S&#227;o Tom&#233; and Pr&iacute;ncipe</span>,
        NL: <span>S&#227;o Tome en Pr&iacute;ncipe</span>
    },
    "BL": {
        "id": 191,
        "suffix": "BL",
        UK: <span>Saint Barth&#233;lemy</span>,
        NL: <span>Saint Barthelemy</span>
    },
    "SH": {
        "id": 192,
        "suffix": "SH",
        UK: <span>Saint Helena</span>,
        NL: <span>Saint Helena</span>
    },
    "KN": {
        "id": 193,
        "suffix": "KN",
        UK: <span>Saint Kitts and Nevis</span>,
        NL: <span>Saint Kitts en Nevis</span>
    },
    "LC": {
        "id": 194,
        "suffix": "LC",
        UK: <span>Saint Lucia</span>,
        NL: <span>Saint Lucia</span>
    },
    "MF": {
        "id": 195,
        "suffix": "MF",
        UK: <span>Saint Martin</span>,
        NL: <span>Saint Martin</span>
    },
    "VC": {
        "id": 196,
        "suffix": "VC",
        UK: <span>Saint Vincent and the Grenadines</span>,
        NL: <span>Saint Vincent en de Grenadines</span>
    },
    "PM": {
        "id": 197,
        "suffix": "PM",
        UK: <span>Saint Pierre and Miquelon</span>,
        NL: <span>Saint&#45;Pierre en Miquelon</span>
    },
    "SB": {
        "id": 198,
        "suffix": "SB",
        UK: <span>Solomon Islands</span>,
        NL: <span>Salomonseilanden</span>
    },
    "SM": {
        "id": 199,
        "suffix": "SM",
        UK: <span>San Marino</span>,
        NL: <span>San Marino</span>
    },
    "SA": {
        "id": 200,
        "suffix": "SA",
        UK: <span>Saudi Arabia</span>,
        NL: <span>Saudi&#45;Arabi&euml;</span>
    },
    "SN": {
        "id": 201,
        "suffix": "SN",
        UK: <span>Senegal</span>,
        NL: <span>Senegal</span>
    },
    "RS": {
        "id": 202,
        "suffix": "RS",
        UK: <span>Serbia</span>,
        NL: <span>Servi&euml;</span>
    },
    "SC": {
        "id": 203,
        "suffix": "SC",
        UK: <span>Seychelles</span>,
        NL: <span>Seychellen</span>
    },
    "SL": {
        "id": 204,
        "suffix": "SL",
        UK: <span>Sierra Leone</span>,
        NL: <span>Sierra Leone</span>
    },
    "SG": {
        "id": 205,
        "suffix": "SG",
        UK: <span>Singapore</span>,
        NL: <span>Singapore</span>
    },
    "SI": {
        "id": 206,
        "suffix": "SI",
        UK: <span>Slovenia</span>,
        NL: <span>Sloveni&euml;</span>
    },
    "SK": {
        "id": 207,
        "suffix": "SK",
        UK: <span>Slovakia</span>,
        NL: <span>Slowakije</span>
    },
    "SO": {
        "id": 208,
        "suffix": "SO",
        UK: <span>Somalia</span>,
        NL: <span>Somali&euml;</span>
    },
    "ES": {
        "id": 209,
        "suffix": "ES",
        UK: <span>Spain</span>,
        NL: <span>Spanje</span>
    },
    "LK": {
        "id": 210,
        "suffix": "LK",
        UK: <span>Sri Lanka</span>,
        NL: <span>Sri Lanka</span>
    },
    "SD": {
        "id": 211,
        "suffix": "SD",
        UK: <span>Sudan</span>,
        NL: <span>Sudan</span>
    },
    "SR": {
        "id": 212,
        "suffix": "SR",
        UK: <span>Suriname</span>,
        NL: <span>Suriname</span>
    },
    "SJ": {
        "id": 213,
        "suffix": "SJ",
        UK: <span>Svalbard and Jan Mayen</span>,
        NL: <span>Svalbard en Jan Mayen</span>
    },
    "SZ": {
        "id": 214,
        "suffix": "SZ",
        UK: <span>Swaziland</span>,
        NL: <span>Swaziland</span>
    },
    "SY": {
        "id": 215,
        "suffix": "SY",
        UK: <span>Syria</span>,
        NL: <span>Syri&euml;</span>
    },
    "TJ": {
        "id": 216,
        "suffix": "TJ",
        UK: <span>Tajikistan</span>,
        NL: <span>Tadzjikistan</span>
    },
    "TW": {
        "id": 217,
        "suffix": "TW",
        UK: <span>Taiwan</span>,
        NL: <span>Taiwan</span>
    },
    "TZ": {
        "id": 218,
        "suffix": "TZ",
        UK: <span>Tanzania</span>,
        NL: <span>Tanzania</span>
    },
    "TH": {
        "id": 219,
        "suffix": "TH",
        UK: <span>Thailand</span>,
        NL: <span>Thailand</span>
    },
    "TG": {
        "id": 220,
        "suffix": "TG",
        UK: <span>Togo</span>,
        NL: <span>Togo</span>
    },
    "TK": {
        "id": 221,
        "suffix": "TK",
        UK: <span>Tokelau</span>,
        NL: <span>Tokelau&#45;eilanden</span>
    },
    "TO": {
        "id": 222,
        "suffix": "TO",
        UK: <span>Tonga</span>,
        NL: <span>Tonga</span>
    },
    "TT": {
        "id": 223,
        "suffix": "TT",
        UK: <span>Trinidad and Tobago</span>,
        NL: <span>Trinidad en Tobago</span>
    },
    "JE": {
        "id": 224,
        "suffix": "JE",
        UK: <span>Jersey</span>,
        NL: <span>Trui</span>
    },
    "TD": {
        "id": 225,
        "suffix": "TD",
        UK: <span>Chad</span>,
        NL: <span>Tsjaad</span>
    },
    "CZ": {
        "id": 226,
        "suffix": "CZ",
        UK: <span>Czech Republic</span>,
        NL: <span>Tsjechische Republiek</span>
    },
    "TN": {
        "id": 227,
        "suffix": "TN",
        UK: <span>Tunisia</span>,
        NL: <span>Tunesi&euml;</span>
    },
    "TR": {
        "id": 228,
        "suffix": "TR",
        UK: <span>Turkey</span>,
        NL: <span>Turkije</span>
    },
    "TM": {
        "id": 229,
        "suffix": "TM",
        UK: <span>Turkmenistan</span>,
        NL: <span>Turkmenistan</span>
    },
    "TC": {
        "id": 230,
        "suffix": "TC",
        UK: <span>Turks and Caicos Islands</span>,
        NL: <span>Turks&#45;en Caicoseilanden</span>
    },
    "TV": {
        "id": 231,
        "suffix": "TV",
        UK: <span>Tuvalu</span>,
        NL: <span>Tuvalu</span>
    },
    "UY": {
        "id": 232,
        "suffix": "UY",
        UK: <span>Uruguay</span>,
        NL: <span>Uruguay</span>
    },
    "VU": {
        "id": 233,
        "suffix": "VU",
        UK: <span>Vanuatu</span>,
        NL: <span>Vanuatu</span>
    },
    "VE": {
        "id": 234,
        "suffix": "VE",
        UK: <span>Venezuela</span>,
        NL: <span>Venezuela</span>
    },
    "AE": {
        "id": 235,
        "suffix": "AE",
        UK: <span>United Arab Emirates</span>,
        NL: <span>Verenigde Arabische Emiraten</span>
    },
    "VN": {
        "id": 238,
        "suffix": "VN",
        UK: <span>Vietnam</span>,
        NL: <span>Vietnam</span>
    },
    "WF": {
        "id": 239,
        "suffix": "WF",
        UK: <span>Wallis and Futuna</span>,
        NL: <span>Wallis en Futuna</span>
    },
    "WS": {
        "id": 240,
        "suffix": "WS",
        UK: <span>Western Samoa</span>,
        NL: <span>West&#45;Samoa</span>
    },
    "EH": {
        "id": 241,
        "suffix": "EH",
        UK: <span>Western Sahara</span>,
        NL: <span>Westelijke Sahara</span>
    },
    "BY": {
        "id": 242,
        "suffix": "BY",
        UK: <span>Belarus</span>,
        NL: <span>Wit&#45;Rusland</span>
    },
    "ZM": {
        "id": 243,
        "suffix": "ZM",
        UK: <span>Zambia</span>,
        NL: <span>Zambia</span>
    },
    "ZW": {
        "id": 244,
        "suffix": "ZW",
        UK: <span>Zimbabwe</span>,
        NL: <span>Zimbabwe</span>
    },
    "ZA": {
        "id": 245,
        "suffix": "ZA",
        UK: <span>South Africa</span>,
        NL: <span>Zuid&#45;Afrika</span>
    },
    "GS": {
        "id": 246,
        "suffix": "GS",
        UK: <span>South Georgia and the South Sandwich Islands</span>,
        NL: <span>Zuid&#45;Georgi&euml; en de Zuidelijke Sandwicheilanden</span>
    },
    "KR": {
        "id": 247,
        "suffix": "KR",
        UK: <span>South Korea</span>,
        NL: <span>Zuid&#45;Korea</span>
    },
    "SE": {
        "id": 248,
        "suffix": "SE",
        UK: <span>Sweden</span>,
        NL: <span>Zweden</span>
    },
    "CH": {
        "id": 249,
        "suffix": "CH",
        UK: <span>Switzerland</span>,
        NL: <span>Zwitserland</span>
    }
}
export default nationalities;
