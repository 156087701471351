import LanguageService from "../../services/languageService";
import {useState} from "react";

function Base(props) {

    const [nextButtonClicked, setNextButtonClicked] = useState(false);
    const [error, setError] = useState(false);
    const [questions, setQuestions] = useState([
        {
            type: "select",
            name: "selectleeftijd",
            value: "",
            options: [
                {value: "", label: `-- ${LanguageService.translate("selectleeftijd")} -- `, key: 0},
                {value: 1, label: "< 12", key: 1},
                {value: 2, label: "13 - 20", key: 2},
                {value: 3, label: "21 - 30", key: 3},
                {value: 4, label: "31 - 40", key: 4},
                {value: 5, label: "41 - 50", key: 5},
                {value: 6, label: "61 - 60", key: 6},
                {value: 7, label: "60+", key: 7}
            ]
        },
        {
            type: "radio",
            name: "geslacht",
            value: "",
            options: [
                {value: "m", label: "man", key: 0},
                {value: "v", label: "vrouw", key: 0},
            ]
        },
        {
            type: "select",
            name: "kijkxp",
            value: "",
            options: [
                {value: "", label: `-- ${LanguageService.translate("xpunvalid")} -- `},
                {value: 2, label: "xp1", key: 0},
                {value: 3, label: "xp2", key: 0},
                {value: 4, label: "xp3", key: 0},
                {value: 5, label: "xp4", key: 0}
            ]
        },
        {
            type: "select",
            name: "mailnationaliteit",
            value: "",
            options: LanguageService.getNationalities()
        }
    ]);

    const verify = () => {
        setNextButtonClicked(true);
        setError(false);
        if (questions.every(question => question.value)) {
            props.setDone(true);
        } else {
            setError(true);
        }
    }

    const valueChanged = (el, name) => {
        setQuestions(prevState =>
            prevState.map(item => {
                if (item.name === name) {
                    item.value = el.target.value;
                }
                return item;
            }))
    }

    return <fieldset>
        <legend><h1>{LanguageService.translate("titel2")}</h1></legend>
        <p>{LanguageService.translate("basisuitleg")}</p>
        {LanguageService.translate('verplicht')}
        <br/>
        {error && <font color={"#ff0000"}>{LanguageService.translate('oningevuld')}</font>}
        <table className={"styled"}>
            <tbody>
            {questions.map(item => {
                return <tr key={item.name} className={nextButtonClicked && !item.value ? 'error' : ''}><td>{LanguageService.translate(item.name)}</td>
                    <td width={"250"}>

                        {item.type === "select" &&
                            <select name={item.name} value={item.value} onChange={el => valueChanged(el, item.name)}>
                                {item.options.map(option => <option key={option.value} value={option.value}>{LanguageService.translate(option.label)}</option>)}
                            </select>
                        }

                        {item.type === "radio" && item.options.map(option =>
                            <label key={option.label}><input type={"radio"} name={item.name} value={option.value} onChange={el => valueChanged(el, item.name)} />{LanguageService.translate(option.label)}&nbsp;&nbsp;</label>
                        )}
                    </td>
                </tr>
            })}
            </tbody>
        </table>

        <input type={"submit"} name={"basis"} value={LanguageService.translate("next")} onClick={verify}/>

    </fieldset>
}

export default Base;
