import styles from "./Quote.module.css";
import {useEffect, useState} from "react";
import QuoteService from "../../../services/quoteService";
import {useLocation} from "react-router-dom";

function Quote() {

    const [quote, setQuote] = useState(QuoteService.getQuote());

    const location = useLocation();

    useEffect(() => {
        setQuote(QuoteService.getQuote());
    }, [location])

    return <div className={styles.quote}>
        <div className={styles.quoteBody}>
            {quote.quote}
        </div>
        <div className={styles.quoteAttribution}>
            {quote.attribution}
        </div>
    </div>

}

export default Quote;
