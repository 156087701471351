
const SURVEY_RESULTS_KEY = "OUR_FILM_RESEARCH:SURVEY:RESULTS";
const SURVEY_SERIES_KEY = "OUR_FILM_RESEARCH:SURVEY:FILLED_OUT_SERIES";

function getKey(key) {
    return JSON.parse(localStorage.getItem(key));
}

function setKeyVal(key, val) {
    localStorage.setItem(key, JSON.stringify(val));
}

const SurveyResponseService = {

    getRandomSeries() {
        const respondedTo = getKey(SURVEY_SERIES_KEY) || [];
        const available = [1,2,3].filter(x => respondedTo.indexOf(x) < 0);
        if (!available.length) {
            available.push(1, 2, 3);
        }
        return available[Math.floor(Math.random()*available.length)];
    },

    updateSurveyResults(response) {
        const previousResponse = getKey(SURVEY_RESULTS_KEY);
        if(previousResponse) {
            Object.keys(response).forEach(key => {
                previousResponse[key] = response[key];
            });
            setKeyVal(SURVEY_RESULTS_KEY, previousResponse);
        } else {
            setKeyVal(SURVEY_RESULTS_KEY, response);
        }
    },

    setSurveyDone(seriesID) {
        const answeredSeries = (getKey(SURVEY_SERIES_KEY) || []).filter(x => x !== seriesID);
        answeredSeries.push(seriesID);
        setKeyVal(SURVEY_SERIES_KEY, answeredSeries);
    },

    getSurveyResponse() {
        return {response: getKey(SURVEY_RESULTS_KEY), serie: getKey(SURVEY_SERIES_KEY)};
    }

}

export default SurveyResponseService;
