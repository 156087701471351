import styles from "./footer.module.css";
import LanguageService from "../../../services/languageService";
import {Link} from "react-router-dom";

function Footer(props) {

    return <footer className={styles.footerjack}>
        <div id={styles.jackdiv} className={props.pagewide ? styles.pagewide : styles.fixedwidth}>
            &nbsp;
        </div>
        <div id={styles.footer}>
            <div id={styles.door}>
                <p className={styles.door}>{LanguageService.translate("by")}</p>
                <h3 className={styles.door}>Dick van Baardewijk</h3><h3 className={styles.door}>Jan de Mooij</h3>
            </div>
            <div id={styles.footermenu}>
                <ul id={styles.footermenu}>
                    <li><Link to={`/survey/${LanguageService.lang}/end`}>Contact</Link></li>
                    <li> | </li>
                    <li><Link to="/disclaimer" >Disclaimer</Link></li>
                </ul>
            </div>
            <h1 id={styles.researchtitle}>{LanguageService.translate("researchtitle")}</h1>

            <div id={styles.copyrightdiv}>
                <p id={styles.copyright}>&copy; Copyright <Link to={'/'} title={"OurFilmResearch"}>www.OurFilmResearch.tk</Link> 2010 - {new Date().getFullYear()}</p>
                <p id={styles.copyright2}>&copy; Copyright Dick van Baardewijk & Jan de Mooij 2010 - {new Date().getFullYear()}</p>
            </div>
        </div>
    </footer>
}

export default Footer;
