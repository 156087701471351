import './survey.css';
import {useCallback, useEffect, useRef, useState} from "react";
import Base from "./base";
import Questions from "./questions";
import End from "./end";
import LanguageService from "../../services/languageService";
import LanguageSelect from "./languageSelect";
import {useParams} from "react-router-dom";
import SurveyResponseService from "../../services/surveyResponseService";

function Survey(props) {
  const PHASE_LANGUAGE_SELECT = "PHASE:LANGUAGE_SELECT";
  const PHASE_BASIC_QUESTIONS = "PHASE:BASIC_QUESTIONS";
  const PHASE_SURVEY = "PHASE:SURVEY";
  const PHASE_END = "PHASE:END";

  const [questionSeries] = useState(SurveyResponseService.getRandomSeries());

  const [hook, setHook] = useState(true);
  const [showGuide, setShowGuide] = useState(false);
  const [basicQuestionsDone, setBasicQuestionsDone] = useState(false);
  const [surveyResponse, setSurveyResponse] = useState(SurveyResponseService.getSurveyResponse().response || {});
  const [surveyDone, setSurveyDone] = useState(false);

  const [phase, setPhase] = useState();

  const container = useRef();

  LanguageService.lang = useParams().lang;
  LanguageService.lang = LanguageService.lang === "UK" || LanguageService.lang === "NL" ? LanguageService.lang : undefined;

  const showcodes = () => {
    setShowGuide(previousState => !previousState);
  };

  const beforeUnloadListener = useCallback((event) => {
      event.preventDefault();
      return LanguageService.translate('stopmetafsluiten');
  }, []);

  useEffect(() => {
    if (hook) {
      window.addEventListener("beforeunload", beforeUnloadListener, {capture: true});
    } else {
      window.removeEventListener("beforeunload", beforeUnloadListener, {capture: true});
    }
    return () => window.removeEventListener("beforeunload", beforeUnloadListener, {capture: true});
  }, [beforeUnloadListener, hook]);

  useEffect(() => {
    SurveyResponseService.updateSurveyResults(surveyResponse);
  }, [surveyResponse]);

  useEffect(() => {
    if (surveyDone) {
      SurveyResponseService.setSurveyDone(questionSeries);
    }
  }, [surveyDone, questionSeries]);

  useEffect(() => {
    if (LanguageService.lang != null && props.showEnd) {
      setHook(false);
      setPhase(PHASE_END);
      setShowGuide(false);
    } else if (LanguageService.lang == null) {
      setPhase(PHASE_LANGUAGE_SELECT);
      setHook(false);
    } else if (!basicQuestionsDone) {
      setPhase(PHASE_BASIC_QUESTIONS);
      setShowGuide(true);
    } else if (!surveyDone) {
      setPhase(PHASE_SURVEY);
      setShowGuide(false);
      container?.current?.scrollIntoView({behavior: "smooth"});
    } else {
      setHook(false);
      setPhase(PHASE_END);
    }
  }, [basicQuestionsDone, props.showEnd, surveyDone])


  return <div className={"pws-body"}>
    <p className={"headerLink"}>
      <a href={"/disclaimer"} title="Disclaimer (English only)" target="_blank" rel="noreferrer">Disclaimer and Credits</a>
    </p>
    <div id="pws-container" className={""} ref={container}>
      {phase === PHASE_LANGUAGE_SELECT && <LanguageSelect setHook={setHook}/>}
      {phase !== PHASE_LANGUAGE_SELECT && phase !== PHASE_END &&
        <fieldset>
          <legend><h1>{LanguageService.translate("titel1")}</h1></legend>
          <div className={"float-right"}>
            <input type="submit" value={LanguageService.translate("toggle")} onClick={() => showcodes()}/>
          </div>
          <div id="guide" className={showGuide ? 'guide-visible' : 'guide-hidden'}>
            <div className={"iframe-container"}>{LanguageService.translate("instructiefilm")}</div>
            <div>{LanguageService.translate("instructie")}</div>
          </div>
        </fieldset>
      }
      {phase !== PHASE_LANGUAGE_SELECT && phase !== PHASE_END && <div className={"infobubble"}>{LanguageService.translate("nolongercollectingdata")}</div>}

      {phase === PHASE_BASIC_QUESTIONS && <Base setDone={setBasicQuestionsDone}/>}
      {phase === PHASE_SURVEY && <Questions setSurveyDone={setSurveyDone} setSurveyResponse={setSurveyResponse} serie={questionSeries} />}
      {phase === PHASE_END && <End response={surveyResponse} serie={questionSeries} />}

    </div>
  </div>
}

export default Survey;
