import VideoItem from "./videoItem";
import DatabaseService from "../../../services/databaseService";
import LanguageService from "../../../services/languageService";
import styled from '../home.module.css';

function Videos(props) {

    return <div>
        <div className={"infobubble"}>{LanguageService.translate("bettercompareavailable")}</div>
        {/*<table className={"styled"}>*/}
        <table id={styled.filmpjestable}>
        {DatabaseService.getVideos().map(video =>
            <VideoItem key={video.ID} {...video} />)}
    </table>
    </div>
}

export default Videos;
