import './ratingBar.css';
import LanguageService from "../../../../services/languageService";

function RatingBar(props) {

    return <div className={"ratingBar"}>
        <span>1</span>
        <div className={"outer"}>
            <div className={"segments"}>
                <span tooltip={LanguageService.translate('aanwezigheid1')}><span/></span>
                <span tooltip={LanguageService.translate('aanwezigheid2')}><span/></span>
                <span tooltip={LanguageService.translate('aanwezigheid2')}><span/></span>
                <span tooltip={LanguageService.translate('aanwezigheid3')}><span/></span>
                <span tooltip={LanguageService.translate('aanwezigheid3')}><span/></span>
                <span tooltip={LanguageService.translate('aanwezigheid4')}><span/></span>
                <span tooltip={LanguageService.translate('aanwezigheid4')}><span/></span>
                <span tooltip={LanguageService.translate('aanwezigheid5')}><span/></span>
            </div>
            <div className={"inner"} style={{left: `${(props.value -1)* 25}%`}}><span>{props.value.toFixed(1)}</span></div>
            {props.entered && <div className={"inner user"} style={{left: `${(props.entered - 1) * 25}%`}}/>}
        </div><span>5</span>
        </div>
}

export default RatingBar;
