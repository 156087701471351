import MovieDetailsRow from "./movieDetailsRow";
import styled from './compare.module.css'
import {useEffect, useState} from "react";
import LanguageService from "../../../services/languageService";
import RatingBar from "./ratingBar/ratingBar";
import DatabaseService from "../../../services/databaseService";

function MovieRow(props) {

    const [original, setOriginal] = useState();
    const [active, setActive] = useState(props.serie? props.serie[props.serie.length - 1] : 1);

    useEffect(() => {
        setOriginal(props.series.find(f => f.original));
    }, [props.series]);

    const fragmentDetails = [
        {key: 0, value: clip => `${LanguageService.translate('series')} ${clip.Vraagserie}`},
        {key: 1, label: "version", value: clip => LanguageService.translate(clip.original ? 'original' : 'edited')},
        {key: 2, element: clip => <div className={"iframe-container"}><iframe src={clip.url} title={clip.Naam}/></div>},
        {key: 3, label: "usedmusic", value: (x) => x.music.naam, href: undefined},
        {key: 4, label: "composernaam", value: (x) => x.music.composer, href: undefined},
        {key: 5, label: "album", value: (x) => x.music.album, href: undefined},
        {key: 6, label: "musicsource", value: (x) => x.music.origin, href: (x) => x.music.origin_url},
    ]

    for(let i = 0; i < 9; i++) {
        fragmentDetails.push({
            key: i + 7,
            label: `Q${i + 1}`,
            value: (clip) =>
                <RatingBar
                    value={DatabaseService.getRating(clip.ID)[`Q${i + 1}`]}
                    entered={props.response ? props.response[`F${clip.ID}Q${i + 1}`] : ''}
                />
        })
    }

    return <div>
        <h2 >{LanguageService.translate('film')} <a href={original?.music.origin_url} target={"_blank"} rel={"noreferrer"}>{original?.music.origin}</a></h2>
        <div className={styled.hasActiveSeriesButton}>

            <div className={`${styled.button} ${styled.prevSeriesButton} ${active <= 1 ? styled.invisible : ''}`}>
                <button onClick={() => setActive(p => p-1)}>&lt;</button>
            </div>

            <table width="100%" className={styled.movieRow}>
                <tbody>
                    {fragmentDetails.map(details =>
                        <MovieDetailsRow key={details.key} series={props.series} activeSerieID={active} {...details}/>
                    )}
                </tbody>
            </table>

            <div className={`${styled.button} ${styled.nextSeriesButton} ${active >= 3 ? styled.invisible : ''}`}>
                <button onClick={() => setActive(p => p + 1)}>&gt;</button>
            </div>
        </div>
    </div>
}

export default MovieRow;
