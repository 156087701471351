import styles from './home.module.css';
import LanguageService from "../../services/languageService";
import Videos from "./videos/videos";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import Disclaimer from "./disclaimer/disclaimer";
import {useCallback, useEffect, useState} from "react";
import Quote from "./quote/Quote";
import TopMenu from "./topMenu/topMenu";
import Footer from "./footer/footer";
import Compare from "./compare/compare";

function Home(props) {

    const location = useLocation();
    const [navigateTo, setNavigateTo] = useState();
    const [downloadSourceCodeDetailsVisible, setDownloadSourceCodeDetailsVisible] = useState(false);
    const [isWide, setIsWide] = useState();

    LanguageService.lang = props.lang;
    LanguageService.lang = LanguageService.lang === "UK" || LanguageService.lang === "NL" ? LanguageService.lang : undefined;

    const getLanguagePath = useCallback(
         (language) => {
            return location.pathname.replace(
                /\/?(?:\w+\/?)?(.*)/,
                (_, l) => `/${language}/${l}`
            )
    }, [location]);

    const switchLanguage = (language) => {
        setNavigateTo(getLanguagePath(language));
    }

    useEffect(() => {
        if (LanguageService.lang === undefined) {
            setNavigateTo(getLanguagePath("UK"));
        } else {
            setNavigateTo(undefined);
        }
    }, [getLanguagePath, setNavigateTo])

    useEffect(() => {
        setIsWide(location.pathname.search("compare") > -1);
    }, [location]);

    return (
        <div className={styles.body + (isWide ? ` ${styles.wide}` : '')}>
        <div className={styles.borderLeft}/>
        <div id={styles.right}>
            {(LanguageService.lang === 'UK') && <img alt={"Nederlands"} src={require("./../../assets/image/netherlf_2fx.gif")} onClick={() => switchLanguage("NL")} />}
            {"NL" === LanguageService.lang && <img alt={"English"} src={require("./../../assets/image/greatbrE.gif")} onClick={() => switchLanguage("UK")}/>}
            <Quote/>
        </div>
        {navigateTo && <Navigate to={navigateTo} />}
        <TopMenu pages={props.pages} switchLanguage={switchLanguage}/>
        <div className={styles.container}>
            <div id={styles.contentright}>
                <div id={styles.content}>
                    <Routes>
                        <Route exact path={'/'} element={LanguageService.done.welcome[LanguageService.lang]}/>
                        <Route path={'/download'} element={
                            LanguageService.done.download[LanguageService.lang](
                                downloadSourceCodeDetailsVisible, setDownloadSourceCodeDetailsVisible)
                        }/>
                        <Route path={'/videos'} element={ <Videos/>}/>
                        <Route path={'/disclaimer'} element={<Disclaimer />} />
                        <Route path={'/compare'} element={<Compare />}/>
                        <Route exact path={"*"} element={<div><h1>Whoops</h1><p>{LanguageService.translate(404)}</p></div>}/>
                    </Routes>
                </div>
            </div>
            <Footer pagewide={false} />
        </div>
    </div>
    )
}

export default Home;
