import './disclaimer.css';
import DatabaseService from "../../../services/databaseService";

function Disclaimer(props) {

    return <div>
        <h1>Disclaimer</h1>
        <h2>USA law:</h2>
        <p>The films and music we used for our research are not ours, we give full and complete attribution off all the film material and music to their creators.
            We only used small parts of the films and music for our school research as is permitted in section 107 of the USA copyright law, see below.<br />
            We also used small parts of music, original soundtrack as well as other music. We do not use it in any way for commercial or business purpose.
            In the Dutch Copyright laws, which counts for us since we are Dutch citizens, it is also permitted to use copyrighted material for research. You can read this below in Dutch. </p>
        <p>Quotation of
            <a href="https://www.copyright.gov/title17/92chap1.html#107" target="_blank" rel={"noreferrer"} title="section 107 of the USA copyright law">&nbsp;
                section 107 from the copyright law of the United States of America
            </a>:
        </p>
        <blockquote><i>"Notwithstanding the provisions of sections 106 and 106A, the fair use of a copyrighted work,
            including such use by reproduction in copies or phonorecords or by any other means specified by that section,
            for purposes such as criticism, comment, news reporting, teaching (including multiple copies for classroom use),
            scholarship, or research, is not an infringement of copyright. In determining whether the use made of a work in
            any particular case is a fair use the factors to be considered shall include &#8212;"</i></blockquote>

        <h2>Nederlandse wet:</h2>
        <p>Ook in Nederland is het toegestaan film en muziek waar copyright op is te gebruiken voor onderzoek.
            Dit staat in Artikel 15c in paragraaf 6 van&nbsp;
            <a href="https://wetten.overheid.nl/BWBR0001886/geldigheidsdatum_27-11-2010" title="Auteurswet van 1912" target="_blank" rel="noreferrer">de Auteurswet van 1912</a>. Wij eisen in geen enkele zin het recht op de film- en muziekfragmenten op.
            Alle eer en rechten liggen bij de makers en rechthouders van de film- en muziekfragmenten zoals deze hieronder zijn genoemd.
            Ook in Amerika, waar het meeste film en beeldmateriaal vandaan komt, is het gebruik van kleine fragmenten voor school onderzoek toegestaan,
            zoals u in het hierboven staande stuk in het Engels kunt lezen.
        </p>
        <blockquote><i>"2. Instellingen van onderwijs en instellingen van onderzoek en de aan die instellingen verbonden bibliotheken en de
            Koninklijke Bibliotheek zijn vrijgesteld van de betaling van een vergoeding voor uitlenen als bedoeld in het eerste lid."</i></blockquote>

        <h1>Credits</h1>

        <h3>We used scenes from the following films:</h3>
        <ul id="creditsUL">
            <li>
                <b>Pirates of the Caribbean: The Curse of the Black Pearl (2003), a Disney film.</b>
                <ul>
                    <li>Director: Gore Verbinski</li>
                    <li>For more information: <a href="https://www.imdb.com/title/tt0325980/" target="_blank" title="IMDB" rel="noreferrer">https://www.imdb.com/title/tt0325980/</a></li>
                </ul>
            </li>
            <li>
                <b>The Lord of the Rings: The Fellowship of the Ring (2001), a New Line Cinema film.</b>
                <ul>
                    <li>Director: Peter Jackson</li>
                    <li>For more information: <a href="https://www.imdb.com/title/tt0120737/" target="_blank" title="IMDB" rel="noreferrer">https://www.imdb.com/title/tt0120737/</a></li>
                </ul>
            </li>
            <li>
                <b>Atilla (2001), a Universal Pictures film.</b>
                <ul>
                    <li>Director: Dick Lowry</li>
                    <li>For more information: <a href="https://www.imdb.com/title/tt0259127/" target="_blank" title="IMDB" rel="noreferrer">https://www.imdb.com/title/tt0259127/</a></li>
                </ul>
            </li>
            <li>
                <b>Le comte de Monte Cristo (1998), a Roissy Films film.</b>
                <ul>
                    <li>Director: Jos&eacute;e Dayan</li>
                    <li>For more information: <a href="https://www.imdb.com/title/tt0167565/" target="_blank" title="IMDB" rel="noreferrer">https://www.imdb.com/title/tt0167565/</a></li>
                </ul>
            </li>
        </ul>
        <h3>We used the following soundtracks:</h3>
        <table className={"styled"}>
            <thead>
                <tr>
                    <th>Track Name</th>
                    <th>Composer</th>
                    <th>Album</th>
                    <th>From the film</th>
                </tr>
            </thead>
            <tbody>
            {
                DatabaseService.getCredits().map(credit =>
                <tr key={credit.ID}>
                    <td>{credit.naam}</td>
                    <td>{credit.composer}</td>
                    <td>{credit.album}</td>
                    <td><a href={credit.origin_url} target={"_blank"} rel={"noreferrer"}>{credit.origin}</a></td>
                </tr>
            )}
            </tbody>
        </table>
    </div>
}

export default Disclaimer;
