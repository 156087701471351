import LanguageService from "../../services/languageService";
import {useRef, useState} from "react";
import language_strings from "../../data/Languages";
import DatabaseService from "../../services/databaseService";

function Questions(props) {

    const fieldset = useRef();
    const filmsInSerie = DatabaseService.getVideosForSeries(props.serie);
    const [currentMovie, setCurrentMovie] = useState(0);

    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);

    const [aanwezigheidValues, setAanwezigheidValues] = useState([...Array(9)]);
    const [gebeurtenisValue, setGebeurtenisValue] = useState("");
    const [opmerking, setOpmerking] = useState("");

    const [genres, setGenres] = useState({
        name: "soortfilm",
        value: [],
        options: [
            'actie', 'avontuur', 'comedy', 'misdaad', 'documentaire', 'drama', 'familie', 'fantasie', 'horror',
            'musical', 'detectieve', 'romantisch', 'sciencefiction', 'sport', 'thriller', 'oorlog', 'western'
        ]
    })

    const [film, setFilm] = useState({
        type: 'select',
        name: 'plekinfilm',
        value: "",
        options: [
            { value: "", label: `-- ${LanguageService.translate("plekinfilmunvalid")} -- ` },
            { value: 1, label: 'q11a' },
            { value: 2, label: 'q11b' },
            { value: 3, label: 'q11c' },
            { value: 4, label: 'q11d' },
            { value: 5, label: 'q11e' },
        ]
    });

    const [herkenning, setHerkenning] = useState({
        type: 'radio',
        name: 'herkenning',
        value: "",
        options: [
            { value: 1, label: 'ja' },
            { value: 2, label: 'nee' }
        ]
    });

    const checkAanwezigheid= (result) => {
        if (!aanwezigheidValues.every(x => x != null)) {
            return false;
        } else if (result !== undefined) {
            [...Array(9)].forEach((_, i) => {
                result[`F${DatabaseService.getVideosForSeries(props.serie)[currentMovie].ID}Q${i+1}`] = aanwezigheidValues[i] + 1;
            });
        }
    }

    const checkGenres = (result) => {
        if (genres.value.length === 0) {
            return false;
        } else {
            let genre_array = ""
            genres.options.forEach((genre, index) => {
                genre_array += language_strings[genre]['UK'].replace(' ', ' ') + " = ";
                genre_array += genres.value.indexOf(genre) < 0 ? 'no' : 'yes'
                genre_array += ";<br/>\n";

            })
            result[`F${DatabaseService.getVideosForSeries(props.serie)[currentMovie].ID}Q10`] = genre_array;
        }
    }

    const checkFilm = (result) => {
        if (!film.value) {
            return false;
        } else {
            result[`F${DatabaseService.getVideosForSeries(props.serie)[currentMovie].ID}Q11`] = parseInt(film.value);
        }
    }

    const checkHerkenning = (result) => {
        if (!herkenning.value) {
            return false;
        } else {
            result[`F${DatabaseService.getVideosForSeries(props.serie)[currentMovie].ID}Q12`] = herkenning.value;
        }
    }

    const checkGebeurtenis = (result) => {
        if (gebeurtenisValue.trim().length === 0) {
            return false;
        } else {
            result[`F${DatabaseService.getVideosForSeries(props.serie)[currentMovie].ID}Q13`] = gebeurtenisValue.trim();
        }
    }

    const reset = () => {
        setSubmitted(false);
        setError(prevState => !prevState);
        setAanwezigheidValues([...Array(9)]);
        setGenres((prevState => {prevState.value = []; return {...prevState}}));
        setFilm((prevState => {prevState.value = ""; return {...prevState}}));
        setHerkenning((prevState => {prevState.value = ""; return {...prevState}}));
        setGebeurtenisValue("");
        setOpmerking("");
        fieldset.current.scrollIntoView({behavior: 'smooth'});
    }

    const verify = () => {
        setSubmitted(true);
        setError(true);
        const results = {};

        if(checkAanwezigheid(results) === false) return;
        if(checkGenres(results) === false) return;
        if(checkFilm(results) === false) return;
        if(checkHerkenning(results) === false) return;
        if(checkGebeurtenis(results) === false) return;

        results[`F${currentMovie}Q14`] = opmerking.trim();
        props.setSurveyResponse(prevState => {
            return {...prevState, ...results};
        });
        if (currentMovie >= 3) {
            props.setSurveyDone(true);
        }
        reset();
        if (currentMovie < 3) {
            setCurrentMovie(prevState => prevState + 1);
        }
    }

    return <fieldset ref={fieldset}>
        <legend>
            <h1>{`${LanguageService.translate("titel3")} ${currentMovie + 1}/${filmsInSerie.length}`}</h1></legend>
            {LanguageService.translate("vragenuitleg")}
            <div className={"iframe-container"}>
                <iframe title={DatabaseService.getVideosForSeries(props.serie)[currentMovie].ID} src={DatabaseService.getVideosForSeries(props.serie)[currentMovie].url}/>
            </div>
            {LanguageService.translate('vragenuitleg2')}
            <ul>
                {[...Array(5)].map((_, i) => <li key={i+1}>{`${i+1} = ${LanguageService.translate('aanwezigheid' + (i+1))}`}</li>)}
            </ul>
            {LanguageService.translate('verplicht')}
            <br/>
             {error && <font color={"#ff0000"}>{LanguageService.translate('oningevuld')}</font>}
            <table className={"styled"}>
                <thead>
                    <tr>
                        <td width={"350"}>
                            {LanguageService.translate("titel4")}
                        </td>
                        <td width='25px'><center>1</center></td>
                        <td width='25px'><center>2</center></td>
                        <td width='25px'><center>3</center></td>
                        <td width='25px'><center>4</center></td>
                        <td width='25px'><center>5</center></td>
                    </tr>
                </thead>
                <tbody>
                {[...Array(9)].map((_, i) =>
                    <tr key={i} className={submitted && aanwezigheidValues[i] == null ? 'error' : ''}>
                        <td>{LanguageService.translate(`Q${i+1}`)}</td>
                        {[...Array(5)].map((_, j) =>
                            <td key={j}>
                                <label style={{display: 'block', width: "100%", height: "100%"}} key={j} title={LanguageService.translate(`aanwezigheid${j+1}`)}>
                                <input type={'radio'} name={`f${currentMovie}q${i+1}`} value={j+1} checked={aanwezigheidValues[i] === j} onChange={() => setAanwezigheidValues(previousState =>
                                    previousState.map((s, index) => {
                                        return index === i ? j : s;
                                    })
                                )}/>
                                </label>
                            </td>
                        )}
                    </tr>
                )}
                </tbody>
            </table>

            <table className={"styled genres"}>
                <tbody>
                    <tr className={submitted && genres.value.length === 0 ? 'error' : ''}>
                        <td width={180}>{LanguageService.translate('soortfilm')}</td>
                        <td>
                            <table>
                                <tbody>
                                    {[...Array(6)].map((_, i) =>
                                        <tr key={i}>
                                            {[...Array(3)].map((_, j) => {
                                                const index = j + (i*3);
                                                 return <td key={j} className={"checkbox"}>
                                                     {genres.options[index] &&
                                                      <label>
                                                          <input type={'checkbox'} name={`f${currentMovie}q10`} value={LanguageService.translate(genres.options[index])}
                                                                 checked={genres.value.indexOf(genres.options[index]) >= 0}
                                                                 onChange={el => {
                                                                     const selected = genres.options[index];
                                                                     setGenres(prevState => {
                                                                         const newState = {...prevState};
                                                                         if (el.target.checked && prevState.value.indexOf(selected) < 0) {
                                                                             newState.value = [...prevState.value, selected];
                                                                         } else {
                                                                             newState.value = [...prevState.value.filter(x => x !== selected)];
                                                                         }
                                                                         return newState;
                                                                     });
                                                                 }}
                                                          />
                                                            {LanguageService.translate(genres.options[index])}
                                                          </label>}
                                                    </td>
                                                }
                                            )}
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </td>
                    </tr>

                    <tr className={submitted && !film.value  ? 'error' : ''}>
                        <td>
                            {LanguageService.translate(film.name)}:
                        </td>
                        <td>
                            <select
                                name={film.name}
                                value={film.value}
                                onChange={(event) => setFilm(prevState => {prevState.value = event.target.value; return {...prevState}})}
                            >
                                {film.options.map(option =>
                                    <option key={option.label} value={option.value}>{LanguageService.translate(option.label)}</option>
                                )}
                            </select>
                        </td>
                    </tr>

                    <tr className={submitted && !herkenning.value ? 'error' : ''}>
                        <td>{LanguageService.translate(herkenning.name)}</td>
                        <td>
                            {herkenning.options.map(option =>
                                <label key={option.label} >
                                    <input
                                        type={"radio"}
                                        name={herkenning.name}
                                        value={option.value}
                                        checked={option.value === herkenning.value}
                                        title={option.label}
                                        onChange={() => setHerkenning(prevState => {
                                            prevState.value = option.value;
                                            return {...prevState};
                                        })}
                                    />
                                    {LanguageService.translate(option.label)}
                                </label>
                            )}
                        </td>
                    </tr>

                    <tr className={submitted && gebeurtenisValue.trim().length === 0 ? 'error' : ''}>
                        <td>{LanguageService.translate('gebeurtenis')}</td>
                        <td><textarea
                            value={gebeurtenisValue}
                            rows={7}
                            onChange={(el) => setGebeurtenisValue(el.target.value)}
                        /></td>
                    </tr>

                    <tr>
                        <td>{LanguageService.translate('opmerking')}</td>
                        <td>
                            <textarea
                                rows={7}
                                value={opmerking} onChange={event => setOpmerking(event.target.value)}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>

            <input type={'submit'} name={"knop"} value={LanguageService.translate('next')} onClick={verify} />
    </fieldset>
}

export default Questions;
