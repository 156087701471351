const database = require('../data/pws_data.json');

const DatabaseService = {

    /** Get the videos belonging to a specific question series **/
    getVideosForSeries(serieID): Array {
        return database.filmpjes.filter(filmpje => filmpje.Vraagserie === serieID).sort((a,b) => (a.ID - b.ID));
    },

    getVideos(): Array {
        const videos = [];
        database.filmpjes.forEach(v => {
            const video = {...v};
            video.music = this.getCreditForVideo(video.credit);
            let origin = video.music;
            if (!video.original) {
                const originalVideo = database.filmpjes.find(x => x.Vergelijkserie === video.Vergelijkserie && x.original);
                origin = this.getCreditForVideo(originalVideo.credit);
            }
            video.origin = origin;
            videos.push(video);
        });
        return videos;
    },

    /** Get all videos, grouped by question series, including credits **/
    getSeries(): Array {
        const series = {};
        database.filmpjes.forEach(film => {
            film.music = database.credits.find(c => c.ID === film.credit);
            if (!(film.Vergelijkserie in series)) {
                series[film.Vergelijkserie] = [];
            }
            series[film.Vergelijkserie].push(film);
        });
        return series;
    },

    sortFilmsBySurveySeries(filmSeries, surveySeriesID) {
        function getRatingForItem(item) {
            if (surveySeriesID) {
                const i = surveySeriesID.indexOf(item.Vraagserie);
                if (i > -1) {
                    return -10 * (i + 10);
                }
            }
            return item.Vraagserie;
        }

        Object.keys(filmSeries).forEach(movieSeriesID => {
            filmSeries[movieSeriesID].sort((a, b) => {
                return getRatingForItem(a) - getRatingForItem(b);
            })
        });
        return filmSeries;
    },

    getCredits(): Array {
        return [...database.credits];
    },

    getCredit(creditID): Object {
        return {...database.credits.find(x => x.ID === creditID)}
    },

    getRating(videoID): Object {
        return {...database.ratings[videoID]};
    },

    getCreditForVideo(creditID): Object {
        return {...database.credits.find(x => x.ID === creditID)}
    }
};

export default DatabaseService;
