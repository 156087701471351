import styles from "../home.module.css";
import {NavLink} from "react-router-dom";
import LanguageService from "../../../services/languageService";

function TopMenu(props) {

    return <div id={styles.menu}>
        <ul id={styles.topmenu}>
            {props.pages.map(page =>
                <li key={page.to}>
                    <NavLink
                        to={`/${LanguageService.lang && !page.blank? LanguageService.lang + '/' : ''}${page.to}`}
                        className={({ isActive }) => isActive ? styles["active-nav-link"] : undefined}
                        target={page.blank ? '_blank' : undefined}
                        rel={page.blank ? 'noreferrer' : undefined}
                    >
                        {page.title}
                    </NavLink>
                </li>)
            }
            {(LanguageService.lang === 'UK') && <li className={styles.hideLarge}><img alt={"Nederlands"} src={require("../../../assets/image/netherlf_2fx.gif")} onClick={() => props.switchLanguage("NL")} /></li>}
            {"NL" === LanguageService.lang && <li className={styles.hideLarge}><img alt={"English"} src={require("../../../assets/image/greatbrE.gif")} onClick={() => props.switchLanguage("UK")}/></li>}
        </ul>
    </div>
}

export default TopMenu;
