function LanguageSelect(props) {

    const setLang = (lang) => {
        window.location = `/survey/${lang}`;
    }

    return <div className={"language-select"}>
        <div>
            <h1>Kies uw taal</h1>
            <h2>Select your language</h2>
                <table>
                    <tbody>
                    <tr>
                        <td className={"language-title"}><h3>English</h3></td>
                        <td className={"language-title"}><h3>Nederlands</h3></td>
                    </tr>
                    <tr>
                        <td className={"language-title"}>
                            <center>
                                <input type="submit"
                                   className={"flag english"}
                                   alt="English"
                                   name="lang"
                                   value="UK"
                                   title="English"
                                   onClick={() => setLang('UK')}
                                />
                            </center><
                            /td>
                        <td className={"language-title"}>
                            <center>
                                <input type="submit"
                                       className={"flag dutch"}
                                       alt="Nederlands"
                                       name="lang"
                                       value="NL"
                                       title="Nederlands"
                                       onClick={() => setLang("NL")}
                                />
                            </center>
                        </td>
                    </tr>
                    </tbody>
                </table>
        </div>
    </div>
}

export default LanguageSelect;
